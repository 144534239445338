import React, { Component } from 'react';
import { Element } from 'react-scroll';
import {
  Link
} from "react-router-dom";

import './SectionWhatDoWeDo.scss';

import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AOS from 'aos';
import 'aos/dist/aos.css';

import { getDataComponentByIdName, replaceNewLine } from './../utils/getDataComponents';

class SectionWhatDoWeDo extends Component {

  state = {
    dataComponent: [
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' }
    ]
  };

  constructor(props) {
    super(props);
    
    getDataComponentByIdName('SectionWhatDoWeDo')
      .then(result => {
        this.setState({
          ...this.state,
          dataComponent: JSON.parse(result.data)
        });
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          dataComponent: [
            {
              'id': 'title',
              'name': 'titulo',
              'text': '¿Qué hacemos?'
            }, {
              'id': 'info',
              'name': 'texto',
              'text': '<p>Resolvemos la falta de información confiable administrativa, contable y fiscal, que impideuna adecuada toma de decisiones, que preocupa y desenfoca al empresario al dispersarsus esfuerzos en tratar de administrar y controlar su empresa.</p> <p class="color-text-dark">El <span class="bold color-text-dark-important">Blindaje Contacrece©</span> es un sistema que permite el aseguramiento del correcto y adecuado registro electrónico y documental de la operación diaria, y que en conjunto con un proceso de acompañamiento empresarial, devolvemos el control de la compañía al empresario</p>'
            }, {
              'id': 'list1',
              'name': 'titulo lista 1',
              'text': 'Mañana Típica y Asistente Administrativa Contacrece©'
            }, {
              'id': 'list11',
              'name': 'texto lista 1',
              'text': '<p>Para asegurar el registro documental de la información de la compañía, nuestra metodología parte del trabajo de la Asistente Administrativa Contacrece© y de la labor que realiza diariamente.</p><p>Nuestra asistente es seleccionada con base a sus habilidades, aptitudes y valores que den certeza al registro contable y administrativo.</p><p>La mañana típica es simplemente el proceso estandarizado y probado por Contacrece que establece las acciones que la asistente debe seguir para contar con información contable y administrativa con calidad PRODUCT©</p>'
            }, {
              'id': 'list2',
              'name': 'titulo lista 2',
              'text': 'Contraloría Progresiva©'
            }, {
              'id': 'list11',
              'name': 'texto lista 2',
              'text': '<p>Es el término creado por <span class="semi-bold">Contacrece©</span> para describir la manera en cómo implementamos la <span class="semi-bold">Contraloría Administrativa y Contable</span> en su compañía.</p><p>Estas dos contralorías le permiten al empresario desarrollar dos grandes fortalezas en su empresa:</p><ol><li>Sanas prácticas administrativas, establecidas acorde tanto a la situación actual de su empresa, como a la visión de lograr desarrollo y crecimiento escalable.</li> <li>Desarrollo de buenos hábitos empresariales que le permite al empresario recuperar el control de su empresa, y al mismo tiempo le devuelven la tranquilidad, confianza y tiempo para disfrutar tanto de su vida profesional, como personal y familiar.</li></ol>'
            }, {
              'id': 'list3',
              'name': 'titulo lista 3',
              'text': 'Contabilidad de Clase Mundial©'
            }, {
              'id': 'list11',
              'name': 'texto lista 2',
              'text': '<p>Como resultado del <span class="semi-bold">Blindaje Contacrece©,</span> nuestros empresarios cuentan de manera diaria y mensual con la información administrativa y contable de su compañía.</p><p class="mt-3">El resultado es un paquete de información financiera, contable y administrativa que cumple con el estándar de calidad <span class="semi-bold">PRODUCT©</span> propietario de <span class="semi-bold">Contacrece©.</span></p><p class="mt-3">Este estándar representa las características de la información que termina como resultado del <span class="semi-bold">Blindaje Contacrece©:</span> <span class="italic">Profunda, Real, Oportuna, Detallada, Útil, Confiable y Transparente.</span></p>'
            }, {
              'id': 'footer',
              'name': 'texto final',
              'text': 'La tecnología desarrollada por <span class="semi-bold-regular color-text-dark-important">Contacrece©</span> está compuesta por tres <span class="regular"> ”building blocks”</span> que han demostrado y comprobado el éxito de nuestro sistema'
            }
          ]
        });
      });
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div>
        <Element name="queHacemos"></Element>
        <div id="queHacemos" className="section-whatDoWeDo">
          <Container maxWidth="lg">
            <div className="title-section-whatDoWeDo color-text-dark">
              <div
                className="title-section color-text-dark-semi semi-bold"
                data-aos="fade-up"
                data-aos-once="true"
                dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[0].text) }}>
              </div>
              <div
                className="subtitle-section text-center light color-text-dark mt-5 mb-5"
                data-aos="fade-up"
                data-aos-once="true"
                dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[8].text) }}>
              </div>
            </div>
            <div className="container-expands mt-3">
              <div
                className="content-expand"
                data-aos="fade-up"
                data-aos-once="true">
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <div className="title-expand">
                      <div className="container-img">
                        <img src="/img/004_icon_mananatipic.svg" alt=""/>
                      </div>
                      <div className="container-title-expand">
                        <div
                          className="title"
                          dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[2].text) }}>
                        </div>
                        {/* <div className="info">
                          Para asegurar el registro documental de la información de la compañía, nuestra metodología parte del trabajo de la Asistente Administrativa Contacrece©
                        </div> */}
                      </div>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="container-section-info-expand">
                      <div className="container-icon">
                        <img className="icon-logo" src="/img/019_manatipica.svg" alt=""/>
                      </div>
                      <div
                        className="container-info"
                        dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[3].text) }}>
                      </div>
                      <div></div>
                      <div className="container-more-information">
                        <Link to="/mañana-tipica">
                          Más información
                        </Link>
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              <div
                className="content-expand" 
                data-aos="fade-up"
                data-aos-once="true">
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <div className="title-expand">
                      <div className="container-img">
                        <img src="/img/005_contraloriaprogresiva.svg" alt=""/>
                      </div>
                      <div className="container-title-expand">
                        <div
                          className="title"
                          dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[4].text) }}>
                        </div>
                        {/* <div className="info">
                          Es el término creado por Contacrece© para describir la manera en cómo implementamos la Contraloría Administrativa y Contable en su compañía.
                        </div> */}
                      </div>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="container-section-info-expand">
                      <div className="container-icon">
                        <img className="icon-logo" src="/img/020_contralorias.svg" alt=""/>
                      </div>
                      <div
                        className="container-info"
                        dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[5].text) }}>
                      </div>
                      <div></div>
                      <div className="container-more-information">
                        <Link to="/contraloria-progresiva">
                          Más información
                        </Link>
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              <div
                className="content-expand"
                data-aos="fade-up"
                data-aos-once="true">
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <div className="title-expand">
                      <div className="container-img">
                        <img src="/img/006_Clasemundial.svg" alt=""/>
                      </div>
                      <div className="container-title-expand">
                        <div
                          className="title"
                          dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[6].text) }}>
                        </div>
                        {/* <div className="info">
                          Como resultado del Blindaje Contacrece©, nuestros empresarios cuentan de manera diaria y mensual con la información administrativa y contable de su compañía.
                        </div> */}
                      </div>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="container-section-info-expand">
                      <div className="container-icon">
                        <img className="icon-logo" src="/img/021_clasemundial.svg" alt=""/>
                      </div>
                      <div
                        className="container-info"
                        dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[7].text) }}>
                      </div>
                      <div></div>
                      <div className="container-more-information">
                        <Link to="/clase-mundial">
                          Más información
                        </Link>
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            </div>
            <div
              className="container-info-what-do-we-do color-text-dark"
              data-aos="fade-up"
              data-aos-once="true"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[1].text) }}>
            </div>
          </Container>
        </div>
      </div>
    )
  } 
}

export default SectionWhatDoWeDo;