import React, { Component } from 'react';

import Container from '@material-ui/core/Container';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './SectionHelp.scss';

import { getDataComponentByIdName, replaceNewLine } from './../utils/getDataComponents';

class SectionHelp extends Component  {

  state = {
    dataComponent: [
      { 'id': 'title', 'name': 'titulo', 'text': '' },
      { 'id': 'text', 'name': 'texto', 'text': '' },
      { 'id': 'background', 'name': 'Imagen de fondo', 'text': '' }
    ]
  };

  constructor(props) {
    super(props);
    
    getDataComponentByIdName('SectionHelp')
      .then(result => {
        this.setState({
          ...this.state,
          dataComponent: JSON.parse(result.data)
        });
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          dataComponent: [
            {
              'id': 'title',
              'name': 'titulo',
              'text': 'Estamos listos para acompañarle' },
            {
              'id': 'text',
              'name': 'texto',
              'text': 'Podemos ayudarle a lograr el crecimiento e institucionalización de su compañía, integrando <span class="bold"> sanas prácticas administrativas y buenos hábitos empresariales </span> que le permitirán siempre alcanzar su meta.' },
            {
              'id': 'background',
              'name': 'Imagen de fondo',
              'text': '/img/018_background.jpg'
            }
          ]
        });
      });
  }
  
  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div
        className="section-between section-help"
        style={{
          backgroundImage: `url("${this.state.dataComponent[2].text}")`
        }}>
        <Container>
          <div
            className="text-center semi-bold title"
            data-aos="fade-up"
            data-aos-once="true">
              <h2
                dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[0].text) }}>
              </h2>
          </div>
          <div
            className="text text-center"
            data-aos="fade-up"
            data-aos-once="true"
            dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[1].text) }}>
          </div>
        </Container>
      </div>
    )
  } 
}

export default SectionHelp;
