import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Posts from './posts/Posts';

import './SectionAllPosts.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

class SectionAllPosts extends Component {

  state = {
    posts: [],
    page: 1,
    loading: false
  };

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
    this.getAllPosts();
  }

  nextPosts() {
    this.setState({
      page: this.state.page + 1
    });
    this.getAllPosts();
  }

  getAllPosts() {
    this.setState({
      loading: true
    });
    fetch(`${global.config.URL.SERVER}/postsByPage/${this.state.page}`, { 
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data) {
        const postList = data.map((post) => ({
            title: post.title ? post.title : '',
            createdBy: post.created_by ? post.created_by : '',
            summary: post.summary ? post.summary : '',
            url: post.url ? post.url : '',
          })
        );
        this.setState({
          posts: [
            ...this.state.posts,
            ...postList
          ],
          loading: false
        });
      }
    })
    .catch( err => {
      console.log(err);
    });
  }

  render() {
    return (
      <div className="section-back-posts">
        <div
          className="card-posts"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-delay="200">

          <div
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-delay="200">
            <h3 className="text-center title-historias mt-4 mb-5">
              Ultimas historias
            </h3>
            <div className="split-line"></div>
          </div>

          <div className="container-posts-list">

            {this.state.posts.map((value, index) => {
              return <Posts key={index} post={value} />
            })}

            { !this.state.loading && 
              <div
                className="all-note text-center"
                data-aos="fade-up"
                data-aos-once="true" >
                <Button className="btn-all-note" onClick={this.nextPosts.bind(this)}>
                  Ver más
                </Button>
              </div>
            }

            { this.state.loading && 
              <div className="text-center">
                <CircularProgress />
              </div>
            }

          </div>
        </div>
      </div>
    )
  } 
}

export default SectionAllPosts;