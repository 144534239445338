import React, { Component } from 'react';

import SectionHeader from '../../generals/sectionHeader/SectionHeader';
import SectionStatistics from '../../generals/sectionStatistics/SectionStatistics';
import SectionHelp from '../../generals/sectionHelp/SectionHelp';
import SectionContact from '../../generals/sectionContact/SectionContact';
import SectionWhatDoWeDo from '../../generals/sectionWhatDoWeDo/SectionWhatDoWeDo';
import SectionAccompaniment from '../../generals/sectionAccompaniment/SectionAccompaniment';

class Home extends Component {
  render() {
    return (
      <div>
        <SectionHeader />
        <SectionStatistics/>
        <SectionWhatDoWeDo/>
        <SectionAccompaniment />
        <SectionContact>
          <SectionHelp />
        </SectionContact>
      </div>
    );
  }
}

export default Home;