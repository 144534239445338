import React, { Component } from 'react';
import { Element } from 'react-scroll';

import Container from '@material-ui/core/Container';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './SectionAccompaniment.scss';

import { getDataComponentByIdName, replaceNewLine } from './../utils/getDataComponents';

class SectionAccompaniment extends Component  {

  state = {
    dataComponent: [
      { 'id': 'title', 'name': 'titulo', 'text': '' },
      { 'id': 'text', 'name': 'texto', 'text': '' },
      { 'id': 'background', 'name': 'Imagen de fondo', 'text': '' },
      { 'id': 'icon', 'name': 'Icono de fondo', 'text': '' }
    ]
  };

  constructor(props) {
    super(props);
    
    getDataComponentByIdName('SectionAccompaniment')
      .then(result => {
        this.setState({
          ...this.state,
          dataComponent: JSON.parse(result.data)
        });
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          dataComponent: [
            {
              'id': 'title',
              'name': 'titulo',
              'text': 'Acompañamiento Empresarial' },
            {
              'id': 'text',
              'name': 'texto',
              'text': 'Contacrece© está conformado por un equipo de trabajo de alto desempeño. Cada miembro de este equipo es un profesional de su área, comprometido y orientado al resultado que tiene años de experiencia, además de contar con estudios de posgrado en áreas administrativas. <span class="semi-bold">Este equipo lo ponemos a la disposición de nuestros clientes</span> como parte de su estructura, adquiriendo de esta manera perfectos aliados que acompañan al empresario y le <span class="semi-bold">ofrecen su experiencia a través de recomendaciones y sugerencias de alto valor.</span>' },
            {
              'id': 'background',
              'name': 'Imagen de fondo',
              'text': '/img/017_background.jpg'
            },
            {
              'id': 'icono',
              'name': 'Icono de fondo',
              'text': '/img/007_icon.svg'
            }
          ]
        });
      });
  }
  
  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div>
        <Element name="acompañamientoEmpresarial"></Element>
        <div
          id="acompañamientoEmpresarial"
          className="section-accompaniment"
          style={{
            backgroundImage: `url(${this.state.dataComponent[2].text})`
          }}>
          <Container>
            <div
              className="text-center semi-bold title"
              data-aos="fade-up"
              data-aos-once="true">
                <h2
                  dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[0].text) }}>
                </h2>
            </div>
            <div
              className="text-center"
              data-aos="fade-up"
              data-aos-once="true">
              <img className="img-people" src={this.state.dataComponent[3].text} alt=""/>
            </div>
            <div
              className="text mt-5"
              data-aos="fade-up"
              data-aos-once="true"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[1].text) }}>
            </div>
          </Container>
        </div>
      </div>
    )
  } 
}

export default SectionAccompaniment;
