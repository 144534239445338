import React, { Component } from 'react';

import SectionHeaderStories from '../../generals/sectionHeaderStories/SectionHeaderStories';
import SectionAllPosts from '../../generals/sectionAllPosts/SectionAllPosts';

import './stories.scss';

class Stories extends Component {
  render() {
    return (
      <div className="container-stories">
        <SectionHeaderStories>
          <SectionAllPosts />
        </ SectionHeaderStories>
      </div>
    );
  }
}

export default Stories;