import React, { Component } from 'react';

import './WhatTypicalMorning.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Container from '@material-ui/core/Container';

import { getDataComponentByIdName, replaceNewLine } from '../../utils/getDataComponents';

class WhatTypicalMorning extends Component {

  state = {
    dataComponent: [
      { 'id': 'title', 'name': 'titulo', 'text': '¿Qué es la Contraloría progresiva Contacrece<span class="text-normal">©</span>?' },
      { 'id': 'subTitle', 'name': 'Sub-titulo', 'text': 'Más del 66% de las Pymes no ha logrado escalar su negocio principalmente debido a un desorden en su capacidad de gestión, control y toma de decisiones administrativo-contables. Y de acuerdo a Forbes, la velocidad de crecimiento de la empresa y la falta de un modelo escalable de crecimiento, en conjunto con una falta de mejores prácticas empresariales son los principales retos que enfrenta la Pyme.' },
      { 'id': 'paragraph1', 'name': 'Parrafo 1', 'text': 'La Contraloría en una compañía es la función que tiene como propósito establecer las políticas y procesos necesarios para controlar los recursos económicos y financieros de una organización, además de vigilar el cumplimiento de objetivos y políticas establecidas, así como la adecuada aplicación de la normativa fiscal en la empresa.' },
      { 'id': 'paragraph2', 'name': 'Parrafo 2', 'text': '<span class="color-text-dark-semi semi-bold">Contraloría Progresiva Contacrece</span> se define como la implementación paulatina de los tipos y niveles de contraloría que requiera y soporte la empresa atendida, en función de su giro, su tamaño, su complejidad, su cultura organizacional y sus niveles de capital humano particulares.' },
      { 'id': 'paragraph3', 'name': 'Parrafo 3', 'text': 'Es un término creado por <span class="color-text-dark-semi semi-bold">Contacrece</span> para describir la estrategia, secuencia y metodología, en el corto y largo plazo, de nuestro plan de servicios.' },
      { 'id': 'paragraph4', 'name': 'Parrafo 4', 'text': 'Dentro del corto plazo, se encuentran disponibles dos tipos de Contraloría:' },
      { 'id': 'imgLeft', 'name': 'Imagen de izquierda', 'text': '/img/grafica_contraloria_1.svg' }
    ]
  };

  constructor(props) {
    super(props);
    
    getDataComponentByIdName('04SectionWhatIs')
      .then(result => {
        console.log(result);
        this.setState({
          ...this.state,
          dataComponent: JSON.parse(result.data)
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          ...this.state,
          dataComponent: [
            { 'id': 'title', 'name': 'titulo', 'text': '¿Qué es la Contraloría progresiva Contacrece<span class="text-normal">©</span>?' },
            { 'id': 'subTitle', 'name': 'Sub-titulo', 'text': 'Más del 66% de las Pymes no ha logrado escalar su negocio principalmente debido a un desorden en su capacidad de gestión, control y toma de decisiones administrativo-contables. Y de acuerdo a Forbes, la velocidad de crecimiento de la empresa y la falta de un modelo escalable de crecimiento, en conjunto con una falta de mejores prácticas empresariales son los principales retos que enfrenta la Pyme.' },
            { 'id': 'paragraph1', 'name': 'Parrafo 1', 'text': 'La Contraloría en una compañía es la función que tiene como propósito establecer las políticas y procesos necesarios para controlar los recursos económicos y financieros de una organización, además de vigilar el cumplimiento de objetivos y políticas establecidas, así como la adecuada aplicación de la normativa fiscal en la empresa.' },
            { 'id': 'paragraph2', 'name': 'Parrafo 2', 'text': '<span class="color-text-dark-semi semi-bold">Contraloría Progresiva Contacrece</span> se define como la implementación paulatina de los tipos y niveles de contraloría que requiera y soporte la empresa atendida, en función de su giro, su tamaño, su complejidad, su cultura organizacional y sus niveles de capital humano particulares.' },
            { 'id': 'paragraph3', 'name': 'Parrafo 3', 'text': 'Es un término creado por <span class="color-text-dark-semi semi-bold">Contacrece</span> para describir la estrategia, secuencia y metodología, en el corto y largo plazo, de nuestro plan de servicios.' },
            { 'id': 'paragraph4', 'name': 'Parrafo 4', 'text': 'Dentro del corto plazo, se encuentran disponibles dos tipos de Contraloría:' },
            { 'id': 'imgLeft', 'name': 'Imagen de izquierda', 'text': '/img/grafica_contraloria_1.svg' }
          ]
        });
      });
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div className="section-WhatTypicalMorning-progressive">
        <div
          className="title text-center"
          data-aos="fade-up"
          data-aos-once="true">
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[0].text) }}>
          </div>
        </div>
        <Container maxWidth="lg">
          <div className="container-info">
            <div
              className="text-center color-text-dark"
              data-aos="fade-up"
              data-aos-once="true"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[1].text) }}>
            </div>
            <div className="general-info">
              <div>
                <div className="image-general-info text-center">
                  <img
                    className="w-100"
                    src={this.state.dataComponent[6].text}
                    alt=""
                    data-aos="fade-up"
                    data-aos-once="true"/>
                </div>
              </div>
              <div>
                <div className="container-line color-text-dark">
                  <div
                    className="line-left"></div>
                  <div
                    data-aos="fade-up"
                    data-aos-once="true"
                    dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[2].text) }}>
                  </div>

                  <p
                    data-aos="fade-up"
                    data-aos-once="true"
                    dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[3].text) }}>
                  </p>

                  <p
                    data-aos="fade-up"
                    data-aos-once="true"
                    dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[4].text) }}>
                  </p>

                  <p
                    data-aos="fade-up"
                    data-aos-once="true"
                    dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[5].text) }}>
                  </p>

                  <div
                    className="container-tipos"
                    data-aos="fade-up"
                    data-aos-once="true">
                    <div className="text-center">
                      <img
                        className="w-100 images-tipos"
                        src="/img/002_001_Contloriprogresiva@0.75x-100.jpg"
                        alt=""/>
                    </div>
                    <div>
                      <span className="color-text-dark-semi semi-bold">Contraloría de la Administración Básica</span>
                    </div>
                    <div className="text-center">
                      <img
                        className="w-100 images-tipos"
                        src="/img/003_001_Contloriprogresiva@0.75x-100.jpg"
                        alt=""/>
                    </div>
                    <div>
                      <span className="color-text-dark-semi semi-bold">Contraloría de la Administración de la Contabilidad</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    )
  } 
}

export default WhatTypicalMorning;