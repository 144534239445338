import React, { Component } from 'react';

import './WhatTypicalMorning.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Container from '@material-ui/core/Container';

import { getDataComponentByIdName, replaceNewLine } from '../../utils/getDataComponents';

class WhatTypicalMorning extends Component {

  state = {
    dataComponent: [
      { 'id': 'title', 'name': 'titulo', 'text': 'Es una contabilidad capaz de darle al empresario el timón de su empresa para llegar a buen puerto, para sortear una tempestad, para mostrarle  alternativas de rutas y probablemente hasta sugerirle la mejor.' },
      { 'id': 'paragraph1', 'name': 'Parrafo 1', 'text': 'Es una contabilidad que en su proceso intrínsecamente cumple nuestro propio estándar de calidad P.R.O.D.U.C.T. esto es que es planificada, abastecida, ejecutada, controlada y retroalimentada con Profundidad, reflejando la Realidad, Oportunamente mes a mes, con el Detalle y Utilidad mínimos para ser suficiente y mostrar lo necesario, siempre Confiable y diseñada lo suficientemente Transparente para que no sea la típica “caja negra” que nadie comprende, para que todo empresario en las Reuniones de Revisión de Resultados pueda comprender lo que le interese ver o analizar de su propia empresa a través de la contabilidad.' },
      { 'id': 'paragraph2', 'name': 'Parrafo 2', 'text': 'Es una contabilidad cuya arquitectura está reflejada en la parametrización administrativa que es la que controla el dia a dia de su empresa y que a su vez representa su propio abastecimiento.' },
      { 'id': 'imgLeft', 'name': 'Imagen de izquierda', 'text': '/img/001_Contr@0.75x-100.jpg' }
    ]
  };

  constructor(props) {
    super(props);
    
    getDataComponentByIdName('03TypicalMorning')
      .then(result => {
        console.log(result);
        this.setState({
          ...this.state,
          dataComponent: JSON.parse(result.data)
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          ...this.state,
          dataComponent: [
            { 'id': 'title', 'name': 'titulo', 'text': 'Es una contabilidad capaz de darle al empresario el timón de su empresa para llegar a buen puerto, para sortear una tempestad, para mostrarle  alternativas de rutas y probablemente hasta sugerirle la mejor.' },
            { 'id': 'paragraph1', 'name': 'Parrafo 1', 'text': 'Es una contabilidad que en su proceso intrínsecamente cumple nuestro propio estándar de calidad P.R.O.D.U.C.T. esto es que es planificada, abastecida, ejecutada, controlada y retroalimentada con Profundidad, reflejando la Realidad, Oportunamente mes a mes, con el Detalle y Utilidad mínimos para ser suficiente y mostrar lo necesario, siempre Confiable y diseñada lo suficientemente Transparente para que no sea la típica “caja negra” que nadie comprende, para que todo empresario en las Reuniones de Revisión de Resultados pueda comprender lo que le interese ver o analizar de su propia empresa a través de la contabilidad.' },
            { 'id': 'paragraph2', 'name': 'Parrafo 2', 'text': 'Es una contabilidad cuya arquitectura está reflejada en la parametrización administrativa que es la que controla el dia a dia de su empresa y que a su vez representa su propio abastecimiento.' },
            { 'id': 'imgLeft', 'name': 'Imagen de izquierda', 'text': '/img/001_Contr@0.75x-100.jpg' }
          ]
        });
      });
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div className="section-que-es-contabilidad">
        <Container maxWidth="lg">
          <div
            className="title text-center"
            data-aos="fade-up"
            data-aos-once="true">
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[0].text) }}>
            </div>
          </div>
          <div className="container-info">
            <div className="general-info">
              <div>
                <div className="image-general-info">
                  <img
                    className="w-100"
                    src={this.state.dataComponent[3].text}
                    alt=""
                    data-aos="fade-up"
                    data-aos-once="true"/>
                </div>
              </div>
              <div>
                <div className="container-line color-text-dark">
                  <div
                    className="line-left"></div>
                  <p
                    data-aos="fade-up"
                    data-aos-once="true"
                    dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[1].text) }}>
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-once="true"
                    dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[2].text) }}>
                  </p>
                </div>

                {/* <p
                  className="container-important color-text-dark"
                  data-aos="fade-up"
                  data-aos-once="true">
                  "El adecuado registro de los eventos o transacciones que afectan la situación patrimonial y los resultados de una entidad económica, así como la traducción y síntesis de dichos eventos o transacciones en reportes ejecutivos, útiles, oportunos, confiables y comparables"
                </p>

                <div className="container-line color-text-dark">
                  <p
                    data-aos="fade-up"
                    data-aos-once="true">
                    Debe representar la fotografía económica "del momento" o "del período" de una entidad, de manera que permita a empleados, directivos y accionistas:
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-once="true">
                    La definición de Contabilidad es:
                  </p>
                  <p
                    className="font-italic"
                    data-aos="fade-up"
                    data-aos-once="true">
                    Tener retroalimentación del resultado que sus acciones y decisiones tienen en el patrimonio de su compañía, así como en el impacto en los objetivos planteados
                  </p>
                  <p
                    className="font-italic"
                    data-aos="fade-up"
                    data-aos-once="true">
                    Tener claridad al respecto de la situación real de la compañía, así como de las tendencias en el comportamiento de KPI’s administrativos, contables, financieros y fiscales, de manera que el empresario mejore, sea ágil, oportuno y asertivo en la toma de decisiones
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </Container>
      </div>
    )
  } 
}

export default WhatTypicalMorning;