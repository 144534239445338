import React, { Component } from 'react';
import { fetchUtils, Admin, Resource } from 'react-admin';
import  simpleRestProvider from 'ra-data-simple-rest';

import authProvider from './../../generals/admin/authProvider/authProvider';

import PostList from './../../generals/admin/posts/posts';
import { PostCreate } from './../../generals/admin/posts/create';
import { PostEdit } from './../../generals/admin/posts/edit';

import ComponentList from './../../generals/admin/components/components';
import { DataComponentEdit } from './../../generals/admin/components/dataComponents';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}

class Home extends Component {
  render() {
    return (
      <div>
        <Admin
          title="Contacrece dashboard"
          authProvider={authProvider}
          dataProvider={simpleRestProvider(global.config.URL.SERVER, httpClient)}>
          <Resource
            name="posts"
            options={{ label: 'Posts' }}
            list={PostList}
            create={PostCreate}
            edit={PostEdit}></Resource>
          <Resource
            name="components"
            options={{ label: 'Componentes' }}
            list={ComponentList}></Resource>
          <Resource
            name="dataComponents"
            options={{ label: '' }}
            edit={DataComponentEdit}></Resource>
        </Admin>
      </div>
    );
  }
}

export default Home;