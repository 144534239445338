import React, { Component } from 'react';
import { Element } from 'react-scroll';

import './SectionStatistics.scss';

import Container from '@material-ui/core/Container';
import ProgressCircle from './../progressCircle/ProgressCircle';

import AOS from 'aos';
import 'aos/dist/aos.css';

import { getDataComponentByIdName, replaceNewLine } from './../utils/getDataComponents';

class SectionStatistics extends Component {

  state = {
    dataComponent: [
      { 'id': 'title', 'name': 'titulo', 'text': '' },
      { 'id': 'sub_text', 'name': 'texto', 'text': '' },
      { 'id': '1_info', 'name': 'texto', 'text': '' },
      { 'id': '2_info', 'name': 'texto', 'text': '' },
      { 'id': '3_info', 'name': 'texto', 'text': '' },
      { 'id': 'image_icon', 'name': 'texto', 'text': '' }
    ]
  };

  constructor(props) {
    super(props);
    
    getDataComponentByIdName('SectionStatistics')
      .then(result => {
        this.setState({
          ...this.state,
          dataComponent: JSON.parse(result.data)
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          ...this.state,
          dataComponent: [
            {
              'id': 'title',
              'name': 'titulo',
              'text': 'Entorno Empresarial'
            },
            {
              'id': 'sub_text',
              'name': 'Sub titulo',
              'text': 'Las empresas que crean el futuro <span class="color-text-dark-semi semi-bold">hacen más que satisfacer</span> <br/> a su cliente y ganárselo a su competidores: <br/> <span class="color-text-dark-important regular">constantemente <span class="semi-bold">lo deslumbran</span></span>'
            },
            {
              'id': '1_info',
              'name': 'Info parrafo 1',
              'text': '<span class="color-text-dark-important regular">El empresario está solo al tomar decisiones, </span> además no cuenta con la <br/> información adecuada para hacerlo…'
            },
            {
              'id': '2_info',
              'name': 'Info parrafo 2',
              'text': 'La mayoría de los empresarios pequeños y medianos:'
            },
            {
              'id': '3_info',
              'name': 'Info parrafo 3',
              'text': '<p> - Sufren de enfermedades derivadas del estrés  y la adicción al trabajo </p> <p> - Dedican una gran cantidad de tiempo, dinero y esfuerzo, tratando de <br/> averiguar como controlar y poner en orden su empresa </p>'
            },
            {
              'id': 'imageIcon',
              'name': 'Info parrafo 3',
              'text': '/img/003_icon.svg'
            }
          ]
        });
      });
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div>
        <Element name="estadisticas"></Element>
        <div id="estadisticas" className="section-statistics">
          <Container maxWidth="xl">
            <h2
              data-aos="fade-up"
              data-aos-once="true"
              className="text-center title-entorno-empresarial"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[0].text) }}
            >
            </h2>
            <div
              className="main-text text-center m-auto color-text-dark"
              data-aos="fade-up"
              data-aos-once="true"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[1].text) }}>
            </div>
            <div
              className="text-center mt-5 mb-3"
              data-aos="fade-up"
              data-aos-once="true">
                <img className="img-futuro" src={this.state.dataComponent[5].text} alt=""/>
            </div>
            <div className="container-statistics">
              <div className="text-center">
                <ProgressCircle porcent={79} color={'255, 139, 0'}>
                  <div>
                    <div
                      className="container-text-progress color-text-dark mt-1">
                        De las Pymes tienen un <br/>
                        <span className="color-yellow">Hombre Orquesta</span> <br/>
                        como empresario
                    </div>
                  </div>
                </ProgressCircle>
                <ProgressCircle porcent={90} color={'255, 72, 0'}>
                  <div
                    className="container-text-progress color-text-dark mt-1">
                      Dicen querer pasar más <br/>
                      tiempo con su familia... <br/>
                      <span className="color-orange">PERO NO PUEDEN</span>
                  </div>
                </ProgressCircle>
              </div>
              <div>
                <div className="container-text-empresas color-text-dark">
                  <div
                    className="line-right pr-5 text-right"
                    data-aos="fade-up"
                    data-aos-once="true">
                    <p
                      dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[2].text) }}>
                    </p>
                    <p
                      className="text-right semi-bold-regular"
                      dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[3].text) }}>
                    </p>
                  </div>
                  <div
                    className="pr-5 font-italic text-right light"
                    data-aos="fade-up"
                    data-aos-once="true"
                    dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[4].text) }}>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    )
  } 
}

export default SectionStatistics;