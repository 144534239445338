import React, { Component } from 'react';

import './AdministrativeAssistant.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Container from '@material-ui/core/Container';

import { getDataComponentByIdName, replaceNewLine } from '../../utils/getDataComponents';

class AdministrativeAssistant extends Component {

  state = {
    dataComponent: [
      { 'id': 'title', 'name': 'titulo', 'text': '¿Porqué es necesario un asistente administrativo?' },
      { 'id': 'text', 'name': 'texto', 'text': 'Es la pieza clave en nuestro <span class="color-text-dark-important semi-bold">Blindaje Contacrece</span>. El asistente Administrativo es una persona que es cuidadosa y detallada seleccionada por tener una serie de habilidades, aptitudes y valores necesarios para que el <span class="color-text-dark-important semi-bold">Blindaje Contacrece</span> se ejecute adecuadamente.' },
      { 'id': 'background', 'name': 'Imagen de fondo', 'text': '/img/003_Mananatipica@0.75x-8.png' }
    ]
  };

  constructor(props) {
    super(props);
    
    getDataComponentByIdName('02SectionAdministrativeAssistant')
      .then(result => {
        this.setState({
          ...this.state,
          dataComponent: JSON.parse(result.data)
        });
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          dataComponent: [
            { 'id': 'title', 'name': 'titulo', 'text': '¿Porqué es necesario un asistente administrativo?' },
            { 'id': 'text', 'name': 'texto', 'text': 'Es la pieza clave en nuestro <span class="color-text-dark-important semi-bold">Blindaje Contacrece</span>. El asistente Administrativo es una persona que es cuidadosa y detallada seleccionada por tener una serie de habilidades, aptitudes y valores necesarios para que el <span class="color-text-dark-important semi-bold">Blindaje Contacrece</span> se ejecute adecuadamente.' },
            { 'id': 'background', 'name': 'Imagen de fondo', 'text': '/img/003_Mananatipica@0.75x-8.png' }
          ]
        });
      });
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div className="section-AdministrativeAssistant">
        <Container maxWidth="lg">
          <div className="text-center title">
            <div
              className="text"
              data-aos="fade-up"
              data-aos-once="true"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[0].text) }}>
            </div>
          </div>
          <div
            className="container-administrativeAssistant text-center"
            data-aos="fade-up"
            data-aos-once="true">
            <img src={this.state.dataComponent[2].text} alt=""/>
          </div>
          <div
            className="info-text text-white text-center"
            data-aos="fade-up"
            data-aos-once="true"
            dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[1].text) }}>
          </div>
          
        </Container>
      </div>
    )
  } 
}

export default AdministrativeAssistant;