import React, { Component } from 'react';

import './SectionHeader.scss';

import Header from './../header/Header'

import AOS from 'aos';
import 'aos/dist/aos.css';

import Container from '@material-ui/core/Container';

import { getDataComponentByIdName, replaceNewLine, orderArray } from './../utils/getDataComponents';

class SectionHeader extends Component {

  state = {
    dataComponent: [
      { 'id': 'title', 'name': 'titulo', 'text': '' },
      { 'id': 'text', 'name': 'texto', 'text': '' },
      { 'id': 'text', 'name': 'texto', 'text': '' }
    ]
  };

  constructor(props) {
    super(props);
    
    getDataComponentByIdName('MainIndex')
      .then(result => {
        console.log(orderArray(JSON.parse(result.data)));
        this.setState({
          ...this.state,
          dataComponent: orderArray(JSON.parse(result.data))
        });
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          dataComponent: [
            {
              'id': 'title',
              'name': 'titulo',
              'text': 'Tranquilidad Administrativa <br/> y Contable' },
            {
              'id': 'text',
              'name': 'texto',
              'text': 'Resolvemos la falta de información confiable administrativa, <br/> contable y fiscal, que impide una adecuada <br/> toma de decisiones' },
            {
              'id': 'text',
              'name': 'texto',
              'text': '/img/000_banner.jpg' }
          ]
        });
      });
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div className="section-header">
        <div
          className="section-background"
          style={{
            backgroundImage: `url(${this.state.dataComponent[2].text})`
          }}>
          <Container maxWidth="xl">
            <Header logo="/img/001_logo_1.svg" />
            <div className="container-text">
              <div
                className="section-text-left"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="200">
                  <h1
                    dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[0].text) }}
                  ></h1>
              </div>
              <div className="mt-4">
                <div
                  className="text-header-main color-text-dark"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="400"
                  dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[1].text) }}
                  >
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    )
  } 
}

export default SectionHeader;