import React, { Component } from 'react';

import './Product.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Container from '@material-ui/core/Container';

import { getDataComponentByIdName, replaceNewLine } from '../../utils/getDataComponents';

class Product extends Component {

  state = {
    dataComponent: [
      { 'id': 'paragraph1', 'name': 'Parrafo 1', 'text': 'Ambas Contralorías se encuentran incluidas en el <span class="semi-bold">Blindaje Contacrece</span>, lo cual representa resultados inmediatos e implica la correcta documentación y el adecuado registro administrativo, así como por consecuencia, contable y financiero de la operación, respaldado lo anterior en procesos de ingeniería.' },
      { 'id': 'paragraph2', 'name': 'Parrafo 2', 'text': 'Estos procesos aumentan la eficiencia del registro, disminuyen la probabilidad de errores y retrabajos, y maximizan los beneficios al generar información que cumple con nuestro Estándar de Calidad <span class="semi-bold">P.R.O.D.U.C.T.</span>' },
      { 'id': 'paragraph3', 'name': 'Parrafo 3', 'text': 'Este estándar de calidad <span class="color-text-dark-important semi-bold">P.R.O.D.U.C.T.</span> fue diseñado para que sea la métrica mínima de desempeño de toda labor realizada, así como de toda información administrativa, contable y financiera que emana de la metodología <span class="color-text-dark-important semi-bold">Contacrece.</span> Estándar definido como un acróstico compuesto por las características que diferencian los productos y servicios de <span class="color-text-dark-important semi-bold">Contacrece:</span>' },
      { 'id': 'paragraph4', 'name': 'Parrafo 4', 'text': 'Cómo resultado de la implementación de la <span class="semi-bold">Contraloría Progresiva,</span><br/> la empresa comienza a lograr ciertos objetivos específicos como:' },
      { 'id': 'paragraph5', 'name': 'Parrafo 5', 'text': 'Contar con la información diaria para una Impecable Administración<br/><br/>Contar con una <span class="semi-bold">Contabilidad de Clase Mundial</span> mensual<br/><br/>Contar con información financiera que le permita una adecuada toma de decisiones<br/><br/>Establecer sistemas de trabajo bajo la óptica de mejora continua<br/><br/>Definir políticas que permitan permear a la organización mejores prácticas empresariales cumplimiento de objetivos<br/><br/>Dar orden y gestionar de manera estratégica los asuntos fiscales de la compañía' },
      { 'id': 'imgLeft', 'name': 'Imagen de derecha', 'text': '/img/004_001_Contloriprogresiva@0.75x-8.png' }
    ],
    expanded: false
  };

  constructor(props) {
    super(props);

    getDataComponentByIdName('04SectionProduct')
      .then(result => {
        console.log(result);
        this.setState({
          ...this.state,
          dataComponent: JSON.parse(result.data)
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          ...this.state,
          dataComponent: [
            { 'id': 'paragraph1', 'name': 'Parrafo 1', 'text': 'Ambas Contralorías se encuentran incluidas en el <span class="semi-bold">Blindaje Contacrece</span>, lo cual representa resultados inmediatos e implica la correcta documentación y el adecuado registro administrativo, así como por consecuencia, contable y financiero de la operación, respaldado lo anterior en procesos de ingeniería.' },
            { 'id': 'paragraph2', 'name': 'Parrafo 2', 'text': 'Estos procesos aumentan la eficiencia del registro, disminuyen la probabilidad de errores y retrabajos, y maximizan los beneficios al generar información que cumple con nuestro Estándar de Calidad <span class="semi-bold">P.R.O.D.U.C.T.</span>' },
            { 'id': 'paragraph3', 'name': 'Parrafo 3', 'text': 'Este estándar de calidad <span class="color-text-dark-important semi-bold">P.R.O.D.U.C.T.</span> fue diseñado para que sea la métrica mínima de desempeño de toda labor realizada, así como de toda información administrativa, contable y financiera que emana de la metodología <span class="color-text-dark-important semi-bold">Contacrece.</span> Estándar definido como un acróstico compuesto por las características que diferencian los productos y servicios de <span class="color-text-dark-important semi-bold">Contacrece:</span>' },
            { 'id': 'paragraph4', 'name': 'Parrafo 4', 'text': 'Cómo resultado de la implementación de la <span class="semi-bold">Contraloría Progresiva,</span><br/> la empresa comienza a lograr ciertos objetivos específicos como:' },
            { 'id': 'paragraph5', 'name': 'Parrafo 5', 'text': 'Contar con la información diaria para una Impecable Administración<br/><br/>Contar con una <span class="semi-bold">Contabilidad de Clase Mundial</span> mensual<br/><br/>Contar con información financiera que le permita una adecuada toma de decisiones<br/><br/>Establecer sistemas de trabajo bajo la óptica de mejora continua<br/><br/>Definir políticas que permitan permear a la organización mejores prácticas empresariales cumplimiento de objetivos<br/><br/>Dar orden y gestionar de manera estratégica los asuntos fiscales de la compañía' },
            { 'id': 'imgLeft', 'name': 'Imagen de derecha', 'text': '/img/004_001_Contloriprogresiva@0.75x-8.png' }
          ]
        });
      });
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  handleChange(panel) {
    this.setState({
      expanded: this.state.expanded === panel ? false : panel
    });
  };

  render() {
    return (
      <div className="section-product">
        <div
          className="container-title text-center text-white"
          data-aos="fade-up"
          data-aos-once="true">
          <Container maxWidth="lg">
            <p
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[0].text) }}>
            </p>
            <p
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[1].text) }}>
            </p>
          </Container>
        </div>
        <div>
          <Container maxWidth="lg">
            <div
              className="container-title-product color-text-dark"
              data-aos="fade-up"
              data-aos-once="true"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[2].text) }}>
            </div>
          </Container>
        </div>
        <div>
          <Container maxWidth="lg">
            <div className="container-words">
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="0">
                <div className="first-letter">P</div>
                <div className="word">Profunda</div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="100">
                <div className="first-letter">R</div>
                <div className="word">Relevante</div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="200">
                <div className="first-letter">O</div>
                <div className="word">Oportuna</div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="300">
                <div className="first-letter">D</div>
                <div className="word">Detallada</div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="400">
                <div className="first-letter">U</div>
                <div className="word">Útil</div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="500">
                <div className="first-letter">C</div>
                <div className="word">Confiable</div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="600">
                <div className="first-letter">T</div>
                <div className="word">Transparente</div>
              </div>
            </div>
          </Container>
        </div>
        <div
          className="container-footer-text text-center text-white"
          data-aos="fade-up"
          data-aos-once="true">
          <Container maxWidth="lg">
            <div className="color-text-white text-center"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[3].text) }}>
            </div>
            <div className="container-info">
              <div className="general-info">
                <div>
                  <div className="text-right container-line color-text-white font-italic"
                    dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[4].text) }}>
                  </div>
                </div>
                <div>
                  <div className="container-line color-text-white">
                    <img
                      className="w-100 image-progressive"
                      src={this.state.dataComponent[5].text}
                      alt=""
                      data-aos="fade-up"
                      data-aos-once="true"/>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    )
  } 
}

export default Product;