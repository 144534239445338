import React, { Component } from 'react';

import SectionHeader from '../../generals/TypicalMorning/sectionHeader/SectionHeader';
import WhatTypicalMorning from '../../generals/TypicalMorning/whatTypicalMorning/WhatTypicalMorning';
import AdministrativeAssistant from '../../generals/TypicalMorning/administrativeAssistant/AdministrativeAssistant';
import SectionHelp from '../../generals/sectionHelp/SectionHelp';
import SectionContact from '../../generals/sectionContact/SectionContact';
import Persons from '../../generals/TypicalMorning/persons/Persons';
import Points from '../../generals/TypicalMorning/points/Points';

class TypicalMorning extends Component {
  render() {
    return (
      <div>
        <SectionHeader />
        <WhatTypicalMorning />
        <Points />
        <AdministrativeAssistant />
        <Persons />
        <SectionContact>
          <SectionHelp />
        </SectionContact>
      </div>
    );
  }
}

export default TypicalMorning;