import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import {
  Link
} from "react-router-dom";

import Button from '@material-ui/core/Button';

import './Posts.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

class SectionAllPosts extends Component {

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div className="posts">
        <div
          className="title"
          data-aos="fade-up"
          data-aos-once="true">
          { renderHTML(this.props.post.title) }
        </div>
        <div
          className="createdBy mt-4 mb-5"
          data-aos="fade-up"
          data-aos-once="true">
          Hecho por &nbsp; / &nbsp; { renderHTML(this.props.post.createdBy) } &nbsp; / &nbsp; Empresa
        </div>
        <div
          className="summary"
          data-aos="fade-up"
          data-aos-once="true">
          { renderHTML(this.props.post.summary) }
        </div>
        <div
          className="full-note"
          data-aos="fade-up"
          data-aos-once="true">
          <Link to={`/blog/${this.props.post.url}`}>
            <Button className="btn-full-note">
              Nota completa
            </Button>
          </Link>
        </div>
      </div>
    )
  } 
}

export default SectionAllPosts;