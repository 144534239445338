import React, { Component } from 'react';

import './SectionHeader.scss';

import Header from './../../header/Header'

import AOS from 'aos';
import 'aos/dist/aos.css';

import Container from '@material-ui/core/Container';

import { getDataComponentByIdName, replaceNewLine } from './../../utils/getDataComponents';

class SectionHeader extends Component {

  state = {
    dataComponent: [
      { 'id': 'title', 'name': 'titulo', 'text': 'Contraloría Progresiva <br/> Contacrece <span class="text-normal">©</span>' },
      { 'id': 'background', 'name': 'Imagen de fondo', 'text': '/img/Back_Contraloria.jpg' }
    ]
  };

  constructor(props) {
    super(props);
    
    getDataComponentByIdName('04SectionHeader')
      .then(result => {
        console.log(result);
        this.setState({
          ...this.state,
          dataComponent: JSON.parse(result.data)
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          ...this.state,
          dataComponent: [
            { 'id': 'title', 'name': 'titulo', 'text': 'Contraloría Progresiva <br/> Contacrece <span class="text-normal">©</span>' },
            { 'id': 'background', 'name': 'Imagen de fondo', 'text': '/img/Back_Contraloria.jpg' }
          ]
        });
      });
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div className="section-header-progressive-accounting">
        <div
          className="section-background"
          style={{
            backgroundImage: `url(${this.state.dataComponent[1].text})`
          }}>
          <Container maxWidth="xl">
            <Header logo="/img/023_logogris.svg" />
            <Container maxWidth="lg">
              <div className="container-text">
                <div
                  className="section-text-left"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="200">
                    <h1
                      dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[0].text) }}
                    ></h1>
                </div>
              </div>
            </Container>
          </Container>
        </div>
      </div>
    )
  } 
}

export default SectionHeader;