import React, { Component } from 'react';

import './Product.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Container from '@material-ui/core/Container';

import { getDataComponentByIdName, replaceNewLine } from '../../utils/getDataComponents';

class Product extends Component {

  state = {
    dataComponent: [
      { 'id': 'title', 'name': 'titulo', 'text': 'Es una contabilidad capaz de dotar al empresario de los reportes de:' },
      { 'id': 'title1', 'name': 'titulo 1', 'text': 'Contabilidad Financiera' },
      { 'id': 'info1', 'name': 'Informacion 1', 'text': 'mas detallados del mercado disponibles para la micro pequeña y mediana empresa, incluyendo Balance General, Estados de Resultados, Flujos de Efectivo, Estado de Origen y Aplicación de Recursos, asi como los reportes de ' },
      { 'id': 'title2', 'name': 'titulo 2', 'text': 'Contabilidad Administrativa' },
      { 'id': 'info2', 'name': 'Informacion 2', 'text': 'que le permiten desde la misma contabilidad controlar y monitorear los costosy gastos de todas la áreas de su empresa, asi como la aplicación de innumerables herramientas de control, adicional a los reportes de' },
      { 'id': 'title3', 'name': 'titulo 3', 'text': 'Contabilidad Fiscal' },
      { 'id': 'info3', 'name': 'Informacion 3', 'text': 'para comprender la conformación de las obligaciones impositivas vigentes de una manera clara, segregada, convincente y consciente para que su toma de decisiones y su planeación sea realizada asertiva y responsablemente, auxiliado por los reportes de' },
      { 'id': 'title4', 'name': 'titulo 4', 'text': 'Contraloría Administrativa y Contable' },
      { 'id': 'info4', 'name': 'Informacion 4', 'text': 'que detallan las observaciones, bajo distintas clasificaciones, resueltas o pendientes, que controlan y retroalimentan al empresario y le dan un rumbo claro a su propio proceso de mejora contínua y le facilita el seguimiento necesario para resolver, de manera predictiva algunos y de forma preventiva otros aspectos, que aseguren la supervivencia, crecimiento y consolidación de su proyecto empresarial.' }
    ],
    expanded: false
  };

  constructor(props) {
    super(props);

    getDataComponentByIdName('03Products')
      .then(result => {
        console.log(result);
        this.setState({
          ...this.state,
          dataComponent: JSON.parse(result.data)
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          ...this.state,
          dataComponent: [
            { 'id': 'title', 'name': 'titulo', 'text': 'Es una contabilidad capaz de dotar al empresario de los reportes de:' },
            { 'id': 'title1', 'name': 'titulo 1', 'text': 'Contabilidad Financiera' },
            { 'id': 'info1', 'name': 'Informacion 1', 'text': 'mas detallados del mercado disponibles para la micro pequeña y mediana empresa, incluyendo Balance General, Estados de Resultados, Flujos de Efectivo, Estado de Origen y Aplicación de Recursos, asi como los reportes de ' },
            { 'id': 'title2', 'name': 'titulo 2', 'text': 'Contabilidad Administrativa' },
            { 'id': 'info2', 'name': 'Informacion 2', 'text': 'que le permiten desde la misma contabilidad controlar y monitorear los costosy gastos de todas la áreas de su empresa, asi como la aplicación de innumerables herramientas de control, adicional a los reportes de' },
            { 'id': 'title3', 'name': 'titulo 3', 'text': 'Contabilidad Fiscal' },
            { 'id': 'info3', 'name': 'Informacion 3', 'text': 'para comprender la conformación de las obligaciones impositivas vigentes de una manera clara, segregada, convincente y consciente para que su toma de decisiones y su planeación sea realizada asertiva y responsablemente, auxiliado por los reportes de' },
            { 'id': 'title4', 'name': 'titulo 4', 'text': 'Contraloría Administrativa y Contable' },
            { 'id': 'info4', 'name': 'Informacion 4', 'text': 'que detallan las observaciones, bajo distintas clasificaciones, resueltas o pendientes, que controlan y retroalimentan al empresario y le dan un rumbo claro a su propio proceso de mejora contínua y le facilita el seguimiento necesario para resolver, de manera predictiva algunos y de forma preventiva otros aspectos, que aseguren la supervivencia, crecimiento y consolidación de su proyecto empresarial.' }
          ]
        });
      });
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  handleChange(panel) {
    this.setState({
      expanded: this.state.expanded === panel ? false : panel
    });
  };

  render() {
    return (
      <div className="section-product-class-world">
        <div
          className="container-title text-center text-white"
          data-aos="fade-up"
          data-aos-once="true">
          <Container maxWidth="lg">
            <div className="title"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[0].text) }}>
            </div>
            <div className="general-info">
              <div className="text-white text-left">
                <p className="bold"
                  dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[1].text) }}>
                </p>
                <p
                  dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[2].text) }}>
                </p>
              </div>
              <div className="text-white text-left">
                <p className="bold"
                  dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[5].text) }}>
                </p>
                <p
                  dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[6].text) }}>
                </p>
              </div>
              <div className="text-white text-left">
                <p className="bold"
                  dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[3].text) }}>
                </p>
                <p
                  dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[4].text) }}>
                </p>
              </div>
              <div className="text-white text-left">
                <p className="bold"
                  dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[7].text) }}>
                </p>
                <p
                  dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[8].text) }}>
                </p>
              </div>
            </div>
          </Container>
        </div>
        {/* <div>
          <Container maxWidth="lg">
            <div className="container-words">
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="0">
                <div className="first-letter">P</div>
                <div className="word">Profundidad</div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="100">
                <div className="first-letter">R</div>
                <div className="word">Relevante</div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="200">
                <div className="first-letter">O</div>
                <div className="word">Oportunidad</div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="300">
                <div className="first-letter">D</div>
                <div className="word">Detallada</div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="400">
                <div className="first-letter">U</div>
                <div className="word">Útil</div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="500">
                <div className="first-letter">C</div>
                <div className="word">Confiable</div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="600">
                <div className="first-letter">T</div>
                <div className="word">Transparente</div>
              </div>
            </div>
          </Container>
        </div> */}
        {/* <div>
          <Container maxWidth="lg">
            <div className="container-info">
              <div className="general-info">
                <div>
                  <div className="text-right container-line color-text-dark">
                    <p
                      data-aos="fade-up"
                      data-aos-once="true">
                      Además, la <span className="color-text-dark-important semi-bold">Contabilidad de Clase Mundial</span> incluye la paulatina implementación de mejores prácticas y hábitos empresariales, mismos que van reordenando a la compañía a operar bajo políticas y procesos administrativos brindando al empresario la tranquilidad de que los saldos administrativos y contables son conciliados todos los meses, realizando cuadres contables en las cuentas claves , asegurando así que las fuentes de información sean confiables.
                    </p>
                    <p
                      data-aos="fade-up"
                      data-aos-once="true">
                      <span className="color-text-dark-important semi-bold">La Contabilidad de Clase Mundial</span> brinda al empresario elementos de información de alto valor para la toma de decisiones mes a mes, y que responden entre otros a los siguientes cuestionamientos:
                    </p>
                    <p
                      className="font-italic"
                      data-aos="fade-up"
                      data-aos-once="true">
                      Análisis comparativo de ventas por línea de negocio <br/>
                      Análisis comparativo de costos por línea de negocio <br/>
                      Análisis comparativo de utilidad bruta por línea de negocio <br/>
                      Análisis comparativo de gastos por departamento <br/>
                      Análisis comparativo de utilidad neta <br/>
                      ¿En dónde está aplicada la utilidad del periodo? <br/>
                      ¿En dónde impacta la pérdida del periodo? <br/>
                      ¿Cuál es el valor de mis activos? <br/>
                      ¿Cuál es el valor de mis pasivos? <br/>
                      ¿Cuánto dinero hay invertido en la empresa? <br/>
                      ¿Mi empresa es rentable? <br/>
                      ¿Mi empresa es solvente?
                    </p>
                  </div>
                </div>
                <div>
                  <div className="container-line color-text-dark">
                    <div
                      className="line-left"></div>

                    <div className="container-levels">
                      <div>
                        Niveles básicos de clientes de la información
                      </div>

                      <div className="colum-levels">
                        <div>
                        </div>
                        <div>
                          <div className="mb-4">
                            Formación del primer nivel del cliente <br/>
                            <span className="color-text-dark-important semi-bold">Procesos administrativos</span> <br/>
                            Visión del control administrativo
                          </div>
                          <div className="mb-4">
                            Segundo nivel de client, supervisor, auditor y "Coash"  <br/>
                            <span className="color-text-dark-important semi-bold">Contabilidad e información financiera</span> <br/>
                            Visión financiera
                          </div>
                          <div className="mb-4">
                            Formación del tercer nivel de cliente <br/>
                            <span className="color-text-dark-important semi-bold">Indicadores operativos x Centro de costo</span> <br/>
                            Visión de productividad
                          </div>
                          <div>
                            Formación del ultimo nivel cliente <br/>
                            <span className="color-text-dark-important semi-bold">Directivos y accionistas</span> <br/>
                            Visión de 360
                          </div>
                        </div>
                      </div>

                      <div>
                        SI EXISTEN CLIENTES EXISTE CONTROL Y RETROALIMENTACIÓN
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div> */}
          {/* <div
            className="container-footer-text text-center text-white"
            data-aos="fade-up"
            data-aos-once="true">
            <Container maxWidth="lg">
              En <span className="semi-bold">Contacrece</span> nuestra prioridad es eliminar la “CAJA NEGRA” que para muchos empresarios representa la contabilidad, por lo que el resultado de nuestro trabajo está diseñado en que sea transparente y claro para todos nuestros clientes.
          </Container>
          </div> */}
        </div>
    )
  } 
}

export default Product;