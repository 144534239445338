import React, { Component } from 'react';

import SectionHeader from '../../generals/ProgressiveAccounting/sectionHeader/SectionHeader';
import WhatTypicalMorning from '../../generals/ProgressiveAccounting/whatTypicalMorning/WhatTypicalMorning';
import SectionHelp from '../../generals/sectionHelp/SectionHelp';
import SectionContact from '../../generals/sectionContact/SectionContact';
import Product from '../../generals/ProgressiveAccounting/product/Product';
import Beneficios from '../../generals/ProgressiveAccounting/beneficios/Beneficios';

class ProgressiveAccounting extends Component {
  render() {
    return (
      <div>
        <SectionHeader />
        <WhatTypicalMorning />
        <Product />
        <Beneficios />
        <SectionContact>
          <SectionHelp />
        </SectionContact>
      </div>
    );
  }
}

export default ProgressiveAccounting;