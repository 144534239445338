import React from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ScrollToTop from './components/generals/scrollTop/scrollTop';
import NavBar from './components/generals/navBar/NavBar';
import Home from './components/pages/home/Home';
import Stories from './components/pages/stories/Stories';
import Post from './components/pages/post/Post';
import TypicalMorning from './components/pages/TypicalMorning/TypicalMorning';
import Admin from './components/pages/admin/Admin';
import NoticePrivacy from './components/pages/noticePrivacy/noticePrivacy';
import WorldClass from './components/pages/WorldClass/WorldClass';
import AboutUs from './components/pages/AboutUs/AboutUs';
import ProgressiveAccounting from './components/pages/ProgressiveAccounting/ProgressiveAccounting';

import config from './config.js';
global.config = config;

function App() {
  return (
    <Router>
      <div>
        <ScrollToTop>
          <Switch>
            <Route path="/admin">
              <Admin/>
            </Route>
            <Route path="/aviso-de-privacidad">
              <div className="nav-bar">
                <NavBar />
              </div>
              <NoticePrivacy />
            </Route>
            <Route path="/mañana-tipica">
              <div className="nav-bar">
                <NavBar />
              </div>
              <TypicalMorning />
            </Route>
            <Route path="/clase-mundial">
              <div className="nav-bar">
                <NavBar />
              </div>
              <WorldClass />
            </Route>
            <Route path="/contraloria-progresiva">
              <div className="nav-bar">
                <NavBar />
              </div>
              <ProgressiveAccounting />
            </Route>
            <Route path="/quienes-somos">
              <div className="nav-bar">
                <NavBar />
              </div>
              <AboutUs/>
            </Route>
            <Route path="/blog/:post" component={Post} />
            <Route path="/blog">
              <div className="nav-bar">
                <NavBar />
              </div>
              <Stories />
            </Route>
            <Route path="/">
              <div className="nav-bar">
                <NavBar />
              </div>
              <Home />
            </Route>
          </Switch>
        </ScrollToTop>
      </div>
    </Router>
  );
}

export default App;
