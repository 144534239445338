import React, { Component } from 'react';
import { Element } from 'react-scroll';
// import { GoogleMap, Marker, withScriptjs, withGoogleMap } from 'react-google-maps';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Collapse from '@material-ui/core/Collapse';
import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import Footer from './../footer/Footer';

// import Map from './../map/map';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './SectionContact.scss';

class SectionContact extends Component  {

  state = {
    email: '',
    comment: '',
    checkedMap: false,
    openMessage: false,
    messageAlert: '',
    iconMessage: ''
  }
  
  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  handleChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleChangeMap () {
    this.setState({
      checkedMap: !this.state.checkedMap
    });
    setTimeout(() => {
      this.footer.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  };

  sendEmail() {
    if (this.state.email !== '' && this.state.comment !== '') {
      var formData = new FormData();
      formData.append('action', 'correo');
      formData.append('email', this.state.email);
      formData.append('message', this.state.comment);

      fetch('./core/sendComments.php', {
        method: 'POST',
        body: formData
      })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            email: '',
            comment: ''
          });
          this.showMessage('Mensaje enviado', <CheckCircleIcon className="mr-2" />);
        } else {
          this.showMessage('Error al enviar el mensaje', <ErrorIcon className="mr-2" />);
        }
      })
      .catch(error => {
        console.log(error);
        this.showMessage('Error al enviar el mensaje', <ErrorIcon className="mr-2" />);
      });
    }
  }

  showMessage(message, icon) {
    this.setState({
      openMessage: true,
      messageAlert: message,
      iconMessage: icon
    });
  };

  hideMessage() {
    this.setState({
      openMessage: false
    });
  };

  render() {
    return (
      <div className="sectionContact">
        <div className="container-contact">
          {this.props.children}
          <Element name="contact"></Element>
          <div
            id="contact"
            className="container-form"
            data-aos="fade-up"
            data-aos-once="true">
            <div className="container-contact-info">
              <div>
                <TextField
                  className="w-100"
                  label="Correo"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange.bind(this)}
                  margin="normal"
                />
                <TextField
                  className="w-100 mt-4"
                  label="Comentario"
                  name="comment"
                  multiline
                  rows="4"
                  value={this.state.comment}
                  onChange={this.handleChange.bind(this)}
                  margin="normal"
                />
                <div className="text-center mt-3">
                  <Button variant="outlined" onClick={this.sendEmail.bind(this)}>
                    ENVIAR
                  </Button>
                </div>
                <div className="section-btn-map  mt-5">
                  <div onClick={this.handleChangeMap.bind(this)} className="btn-map">
                    <img src="/img/008_icon.svg" alt=""/>
                  </div>
                  <div className="text-address">
                    Calle La Luna 2658 <br/>
                    Col. Jardines del Bosque <br/>
                    Guadalajara, Jal. <br/>
                    México <br/>
                    C.P. 44520
                  </div>
                </div>
              </div>
              <div>
                <div className="title-contact uppercase">
                  CONTÁCTANOS
                </div>
                <div className="send-message mt-3">
                  Envíanos un mensaje para cualquier información
                </div>
                <div className="mt-4 orCall">
                  ó llámanos y con gusto te atenderemos
                </div>
                <div className="mt-3">
                  <a href="tel:3316390600" className="phone">
                    (33) 1639 - 0600
                  </a>
                </div>
                <div className="mt-5 contact-text-info">
                  Cualquier idea que quieras compartir, oportunidad de negocio 
                  o simplemente quieres conocernos <a href="mailto:info@contacrece.com" rel="noopener noreferrer" target="_blank" className="link"> envíanos un correo</a>
                </div>
                <div className="mt-5 content-socialnetwork">
                  <div className="title-follow">
                    Síguenos
                  </div>
                  <div className="icon-socialNetwork">
                    {/* <img src="/img/009_facebook.svg" alt=""/> */}
                    {/* <img src="/img/010_instagram.svg" alt=""/> */}
                    {/* <img src="/img/011_telegram.svg" alt=""/> */}
                    <a href="https://www.linkedin.com/company/conta-crece" target="_blank" rel="noopener noreferrer">
                      <img src="/img/012_linkedin.svg" alt=""/>
                    </a>
                    <a href="https://twitter.com/contacrece" target="_blank" rel="noopener noreferrer">
                      <img src="/img/013_twitter.svg" alt=""/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-footer" ref={footer => { this.footer = footer; }}>
            <Collapse in={this.state.checkedMap} timeout={500}>
              {/* <Map /> */}
              <iframe className="map" src="https://www.google.com/maps/d/embed?mid=18w3LK0J-rhx3I4bgZkzbizkdkHSaVHn7&ll=20.66799498446642%2C-103.3845562434638&z=14" async width="640" height="480" title="contacrece"></iframe>
            </Collapse>
            <Footer />
          </div>
        </div>
        <Snackbar
          open={this.state.openMessage}
          onClose={this.hideMessage.bind(this)}
          onClick={this.hideMessage.bind(this)}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <span id="message-id">
              {this.state.iconMessage}
              {this.state.messageAlert}
            </span>
          }
        />
      </div>
    )
  } 
}

export default SectionContact;
