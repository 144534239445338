import React, { Component } from 'react';

import './Product.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Container from '@material-ui/core/Container';

import { getDataComponentByIdName, replaceNewLine } from '../../utils/getDataComponents';

class Product extends Component {

  state = {
    dataComponent: [
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' },
      { 'id': '', 'name': '', 'text': '' }
    ]
  };

  constructor(props) {
    super(props);
    
    getDataComponentByIdName('05DescriptionAboutUs')
      .then(result => {
        this.setState({
          ...this.state,
          dataComponent: JSON.parse(result.data)
        });
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          dataComponent: [
            {
              "id": "section1Title",
              "name": "Seccion 1 titulo",
              "text": "Nuestra misión a nuestros Clientes"
            },
            {
              "id": "section1Text",
              "name": "Seccion 1 texto",
              "text": "El crecimiento e institucionalización de nuestros clientes a través de la implementación de sanas prácticas administrativas y el desarrollo de buenos hábitos empresariales."
            },
            {
              "id": "section2Title",
              "name": "Seccion 2 titulo",
              "text": "Nuestra misión a nuestros Colaboradores"
            },
            {
              "id": "section2Text",
              "name": "Seccion 2 texto",
              "text": "La preparación, crecimiento y consolidación personal y profesional de nuestros colaboradores, dentro de un proceso en excelencia de desarrollo humano y organizacional, continuo, medible y palpable."
            },
            {
              "id": "section3Title",
              "name": "Seccion 3 titulo",
              "text": "Nuestro propósito"
            },
            {
              "id": "section3Text",
              "name": "Seccion 3 texto",
              "text": "Ser una fabrica de sueños y realidades humanamente responsable,\nuna institución moldeadora y forjadora de seres humanos plenos,\nque a través del camino que iremos construyendo,\nlleguemos local, regional o globalmente,\na influir en el curso sustentable de nuestras sociedades y de nuestro futuro"
            },
            {
              "id": "section4Title",
              "name": "Seccion 4 titulo",
              "text": ""
            },
            {
              "id": "section4Text",
              "name": "Seccion 4 texto",
              "text": "Hemos constatado como el servicio del Blindaje Contacrece © , en conjunto con la colaboración de nuestros clientes para implementar mejores hábitos y prácticas empresariales, han traído como resultado un beneficio real en sus empresas.<br/><br/>Por esta razón, la trascendencia de nuestra acciones está planteada escalablemente como un complemento para el mundo empresarial de la micro, pequeña y mediana empresa, por lo cual:"
            },
            {
              "id": "section5Title",
              "name": "Seccion 5 titulo",
              "text": "Nuestra visión es"
            },
            {
              "id": "section5Text",
              "name": "Seccion 5 texto",
              "text": "Desarrollar un modelo de ecosistema laboral rentable y escalable, \ndonde equipos de profesionales comprometidos con la excelencia, \nen el sevir a la micro, pequeña y mediana empresa,\ncon toda su inteligencia, capacidad y voluntad, \nencuentren satisfacción, realización, crecimiento y aprendizaje, \nademás de una buena y eficaz manera de servir al prójimo, a su comunidad y a su país."
            },
            {
              "id": "section6Title",
              "name": "Seccion 6 titulo",
              "text": "Nuestro Equipo"
            },
            {
              "id": "section6Text",
              "name": "Seccion 6 texto",
              "text": "Contacrece esta integrado por un equipo de trabajo de alto desempeño, conformado por profesionales sumamente capaces, orientados a resultados y servicio al cliente, preparados para ofrecer  el acompañamiento que el empresario necesita. \n\nEste gran equipo de profesionales está sumamente comprometido con el beneficio, desarrollo y bienestar de las empresas a las que atienden. Por esta razón, no es poco común que nuestros clientes encuentren en este organigrama extendido de expertos, el consejo, la recomendación y la orientación necesarias para una toma de decisiones informada y estratégica.\n\nEn Contacrece, a través de nuestro equipo, potenciamos el crecimiento de nuestros clientes."
            },
            { 'id': 'background', 'name': 'Imagen de fondo propósito', 'text': '/img/Back_quienessomos_002.jpg' },
            { 'id': 'background', 'name': 'Imagen de fondo visión', 'text': '/img/Back_quienessomos_003.jpg' },
            { 'id': 'icon', 'name': 'icono blindaje contacrece', 'text': '/img/002_quienesomos.svg' }
          ]
        });
      });
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  handleChange(panel) {
    this.setState({
      expanded: this.state.expanded === panel ? false : panel
    });
  };

  render() {
    return (
      <div className="section-product-nosotros">
        <div
          className="container-title-1 text-white"
          data-aos="fade-up"
          data-aos-once="true">
          <Container maxWidth="lg">
            <div
              className="subtitle"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[0].text) }}>
            </div>
            <p
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[1].text) }}>
            </p>
          </Container>
        </div>
        <div
          className="container-title text-white"
          data-aos="fade-up"
          data-aos-once="true">
          <Container maxWidth="lg">
            <div
              className="subtitle"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[2].text) }}>
            </div>
            <p
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[3].text) }}>
            </p>
          </Container>
        </div>
        <div
          className="container-title-person color-text-dark-important"
          style={{
            backgroundImage: `url(${this.state.dataComponent[12].text})`
          }}
          data-aos="fade-up"
          data-aos-once="true">
          <Container maxWidth="lg">
            <div
              className="text-center subtitle bold title-nuestro-proposito"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[4].text) }}>
            </div>
            <p
              className="text-center text-nuestro-proposito"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[5].text) }}>
            </p>
          </Container>
        </div>
        <div className="container-nuestra-vision">
          <Container maxWidth="lg">
            <div className="container-info">
              <div className="general-info">
                <div className="text-center">
                  <img className="img-valores" src={this.state.dataComponent[14].text} alt=""/>
                </div>
                <div>
                  <div className="container-line color-text-dark">
                    <div
                      className="line-left"></div>

                    <div className="container-levels">
                      <div
                        className="title color-text-dark-semi semi-bold"
                        dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[6].text) }}>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[7].text) }}>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div
          className="container-title-flag text-center color-text-dark-important"
          style={{
            backgroundImage: `url(${this.state.dataComponent[13].text})`
          }}
          data-aos="fade-up"
          data-aos-once="true">
          <Container maxWidth="lg">
            <div
              className="subtitle bold title-nuestro-vision"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[8].text) }}>
            </div>
            <p
              className="text-nuestro-vision"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[9].text) }}>
            </p>
          </Container>
        </div>
        <div
          className="container-title-3 text-white"
          data-aos="fade-up"
          data-aos-once="true">
          <Container maxWidth="lg">
            <div
              className="subtitle"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[10].text) }}>
            </div>
            <p
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[11].text) }}>
            </p>
          </Container>
        </div>
      </div>
    )
  } 
}

export default Product;