import React, { Component } from 'react';

import './Persons.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Container from '@material-ui/core/Container';

import { getDataComponentByIdName, replaceNewLine } from '../../utils/getDataComponents';

class Persons extends Component {

  state = {
    dataComponent: [
      { 'id': 'paragraph1', 'name': 'Parrafo 1', 'text': 'A través de una serie de pruebas y análisis evaluamos al candidato. Este filtro es tan importante que solo 1 de cada 10 personas cumplen los estándares mínimos de <span class="color-text-dark-important semi-bold">Contacrece.</span>' },
      { 'id': 'paragraph2', 'name': 'Parrafo 2', 'text': 'Al finalizar el asistente seleccionado es entrenado, acompañado, constantemente capacitado y supervisado por personal experto en procesos administrativos, asegurando que la ejecución de la Mañana Típica se realice con los mayores estándares de certeza y eficiencia.' },
      { 'id': 'paragraph3', 'name': 'Parrafo 3', 'text': 'El Asistente Administrativo seleccionado desempeña su labor dentro de las instalaciones de la empresa de nuestro cliente. A través de este proceso de selección aseguramos que la persona disfrute las tareas a realizar y tengamos como consecuencia que sea alguien que es altamente productivo, efectivo, eficiente, organizado, responsable y confiable. Un valor agregado más aportado por <span class="color-text-dark-important semi-bold">Contacrece</span> a las empresas de nuestros clientes y su crecimiento.' },
      { 'id': 'background', 'name': 'Imagen de fondo', 'text': '/img/005_backMananatipica@0.75x-100.jpg' },
      { 'id': 'imgRight', 'name': 'Imagen derecha', 'text': '/img/004_Mananatipica@0.75x-8.png' }
    ]
  };

  constructor(props) {
    super(props);
    
    getDataComponentByIdName('02SectionPeople')
      .then(result => {
        this.setState({
          ...this.state,
          dataComponent: JSON.parse(result.data)
        });
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          dataComponent: [
            { 'id': 'paragraph1', 'name': 'Parrafo 1', 'text': 'A través de una serie de pruebas y análisis evaluamos al candidato. Este filtro es tan importante que solo 1 de cada 10 personas cumplen los estándares mínimos de <span class="color-text-dark-important semi-bold">Contacrece.</span>' },
            { 'id': 'paragraph2', 'name': 'Parrafo 2', 'text': 'Al finalizar el asistente seleccionado es entrenado, acompañado, constantemente capacitado y supervisado por personal experto en procesos administrativos, asegurando que la ejecución de la Mañana Típica se realice con los mayores estándares de certeza y eficiencia.' },
            { 'id': 'paragraph3', 'name': 'Parrafo 3', 'text': 'El Asistente Administrativo seleccionado desempeña su labor dentro de las instalaciones de la empresa de nuestro cliente. A través de este proceso de selección aseguramos que la persona disfrute las tareas a realizar y tengamos como consecuencia que sea alguien que es altamente productivo, efectivo, eficiente, organizado, responsable y confiable. Un valor agregado más aportado por <span class="color-text-dark-important semi-bold">Contacrece</span> a las empresas de nuestros clientes y su crecimiento.' },
            { 'id': 'background', 'name': 'Imagen de fondo', 'text': '/img/005_backMananatipica@0.75x-100.jpg' },
            { 'id': 'imgRight', 'name': 'Imagen derecha', 'text': '/img/004_Mananatipica@0.75x-8.png' }
          ]
        });
      });
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div className="section-Persons">
        <div
          className="background-section"
          style={{
            backgroundImage: `url(${this.state.dataComponent[3].text})`
          }}>
          <Container maxWidth="lg">
            <div className="general-info">
              <div className="text-white">
                <p
                  data-aos="fade-up"
                  data-aos-once="true"
                  dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[0].text) }}>
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-once="true"
                  dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[1].text) }}>
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-once="true"
                  dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[2].text) }}>
                </p>
              </div>
              <div>
                <div className="image-general-info text-center">
                  <img
                    className="w-100 img-persons"
                    src={this.state.dataComponent[4].text}
                    alt=""
                    data-aos="fade-up"
                    data-aos-once="true"/>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    )
  } 
}

export default Persons;