import React, { Component } from 'react';

import NavBar from './../../generals/navBar/NavBar';
import SectionHeaderPost from '../../generals/sectionHeaderPost/SectionHeaderPost';
import SectionPosts from '../../generals/sectionPosts/SectionPosts';

import './Post.scss';

class Post extends Component {

  state = {
    post: {}
  };

  componentDidMount() {
    this.getPost();
  }

  getPost() {
    this.setState({
      loading: true
    });
    fetch(`${global.config.URL.SERVER}/postsByUrl/${this.props.match.params.post}`, { 
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if (data) {
        this.setState({
          post: data
        });
      }
    })
    .catch( err => {
      console.log(err);
    });
  }

  render() {
    return (
      <div>
        <div className="nav-bar">
          <NavBar />
        </div>
        <div className="container-stories">
          <SectionHeaderPost post={this.state.post}>
            <SectionPosts post={this.state.post} />
          </ SectionHeaderPost>
        </div>
      </div>
    );
  }
}

export default Post;