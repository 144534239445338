import React, { Component } from 'react';

import './SectionHeader.scss';

import Header from './../../header/Header'

import AOS from 'aos';
import 'aos/dist/aos.css';

import Container from '@material-ui/core/Container';

import { getDataComponentByIdName, replaceNewLine } from './../../utils/getDataComponents';

class SectionHeader extends Component {

  state = {
    dataComponent: [
      { 'id': 'title', 'name': 'titulo', 'text': '¿Quiénes somos?'},
      { 'id': 'background', 'name': 'Imagen de fondo', 'text': '/img/Back_quienessomos_001.jpg' }
    ]
  };

  constructor(props) {
    super(props);
    
    getDataComponentByIdName('05MainAboutUs')
      .then(result => {
        this.setState({
          ...this.state,
          dataComponent: JSON.parse(result.data)
        });
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          dataComponent: [
            { 'id': 'title', 'name': 'titulo', 'text': '¿Quiénes somos?' },
            { 'id': 'background', 'name': 'Imagen de fondo', 'text': '/img/Back_quienessomos_001.jpg' }
          ]
        });
      });
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div className="section-header-nosotros">
        <div
          className="section-background"
          style={{
            backgroundImage: `url(${this.state.dataComponent[1].text})`
          }}>
          <Container maxWidth="xl">
            <Header logo="/img/023_logogris.svg" />
            <Container maxWidth="lg">
              <div className="container-text">
                <div
                  className="section-text-left"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="200">
                    <div className="text-center">
                      <img
                        className="icon-header" src="/img/000_logoicon.svg" alt=""/>
                    </div>
                    <h1
                      className="text-center title"
                      dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[0].text) }}
                    ></h1>
                </div>
              </div>
            </Container>
          </Container>
        </div>
      </div>
    )
  } 
}

export default SectionHeader;