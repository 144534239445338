import React, { Component } from 'react';

import './Beneficios.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Container from '@material-ui/core/Container';

import { getDataComponentByIdName, replaceNewLine } from '../../utils/getDataComponents';

class Product extends Component {

  state = {
    dataComponent: [
      { 'id': 'title', 'name': 'titulo', 'text': 'Beneficios' },
      { 'id': 'paragraph1', 'name': 'Parrafo 1', 'text': 'El resultado de que una empresa alcance estos objetivos se traduce entonces en un sistema de control interno que al empresario le trae por consecuencia, entre otros, los siguientes beneficios:' },
      { 'id': 'paragraph2', 'name': 'Parrafo 2', 'text': 'Contar con el tiempo disponible para dedicarlo a incrementar su calidad de vida o a otras áreas de la empresa donde su tiempo es mejor invertido' },
      { 'id': 'paragraph3', 'name': 'Parrafo 3', 'text': 'Tranquilidad de saber que tiene la información y los procesos necesarios para que su compañía crezca' },
      { 'id': 'paragraph4', 'name': 'Parrafo 4', 'text': 'Confianza al ser capaz de delegar en su equipo de trabajo las responsabilidades de la operación de la compañía, dejando de ser indispensable en la empresa, y convirtiéndose en necesario para la escalabilidad y visión estratégica' },
      { 'id': 'paragraph5', 'name': 'Parrafo 5', 'text': 'Control de la compañía sin la necesidad de tener estructuras excesivamente burocráticas, y sin afectar la calidad del ambiente laboral, contribuyendo a mejorar de esta manera la productividad, efectividad y eficiencia de la empresa' },
      { 'id': 'imgLeft', 'name': 'Imagen de derecha', 'text': '/img/005_001_Contloriprogresiva@0.75x-100.jpg' }
    ],
    expanded: false
  };

  constructor(props) {
    super(props);

    getDataComponentByIdName('04SectionBeneficios')
      .then(result => {
        console.log(result);
        this.setState({
          ...this.state,
          dataComponent: JSON.parse(result.data)
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          ...this.state,
          dataComponent: [
            { 'id': 'title', 'name': 'titulo', 'text': 'Beneficios' },
            { 'id': 'paragraph1', 'name': 'Parrafo 1', 'text': 'El resultado de que una empresa alcance estos objetivos se traduce entonces en un sistema de control interno que al empresario le trae por consecuencia, entre otros, los siguientes beneficios:' },
            { 'id': 'paragraph2', 'name': 'Parrafo 2', 'text': 'Contar con el tiempo disponible para dedicarlo a incrementar su calidad de vida o a otras áreas de la empresa donde su tiempo es mejor invertido' },
            { 'id': 'paragraph3', 'name': 'Parrafo 3', 'text': 'Tranquilidad de saber que tiene la información y los procesos necesarios para que su compañía crezca' },
            { 'id': 'paragraph4', 'name': 'Parrafo 4', 'text': 'Confianza al ser capaz de delegar en su equipo de trabajo las responsabilidades de la operación de la compañía, dejando de ser indispensable en la empresa, y convirtiéndose en necesario para la escalabilidad y visión estratégica' },
            { 'id': 'paragraph5', 'name': 'Parrafo 5', 'text': 'Control de la compañía sin la necesidad de tener estructuras excesivamente burocráticas, y sin afectar la calidad del ambiente laboral, contribuyendo a mejorar de esta manera la productividad, efectividad y eficiencia de la empresa' },
            { 'id': 'imgLeft', 'name': 'Imagen de derecha', 'text': '/img/005_001_Contloriprogresiva@0.75x-100.jpg' }
          ]
        });
      });
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  handleChange(panel) {
    this.setState({
      expanded: this.state.expanded === panel ? false : panel
    });
  };

  render() {
    return (
      <div className="section-beneficios">
        <div>
          <Container maxWidth="lg">
            <div className="text-center title"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[0].text) }}>
            </div>
            <div className="container-info">
              <div className="general-info">
                <div>
                  <div className="text-center">
                    <img
                      className="w-100 image-beneficios"
                      src={this.state.dataComponent[6].text}
                      alt=""
                      data-aos="fade-up"
                      data-aos-once="true"/>
                  </div>
                </div>
                <div>
                  <div className="container-line color-text-dark">
                    <div
                      className="line-left"></div>

                      <p
                        data-aos="fade-up"
                        data-aos-once="true"
                        dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[1].text) }}>
                      </p>

                      <p
                        className="font-italic"
                        data-aos="fade-up"
                        data-aos-once="true"
                        dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[2].text) }}>
                      </p>

                      <p
                        className="font-italic"
                        data-aos="fade-up"
                        data-aos-once="true"
                        dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[3].text) }}>
                      </p>

                      <p
                        className="font-italic"
                        data-aos="fade-up"
                        data-aos-once="true"
                        dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[4].text) }}>
                      </p>

                      <p
                        className="font-italic"
                        data-aos="fade-up"
                        data-aos-once="true"
                        dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[5].text) }}>
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    )
  } 
}

export default Product;