import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';

import { replaceNewLine } from './../../utils/getDataComponents';

import './office.scss';

class OfficeSvg extends Component {

  state = {
    anchorPoint: null,
    anchorPointId: null,
    textPopUp: '',
    idPopUp: 0,
    listTexts: [
// 1
      '<p>Saldo por documento</p><p>Saldo por cliente</p><p>Antigüedad de la cartera</p><p>Vencimiento de las cuentas por cobrar</p>',
// 2
      '<p>Saldo por documento</p><p>Saldo por proveedor</p><p>Antigüedad de las cuentas por pagar</p><p>Vencimiento de las cuentas por pagar</p><p>Programación de pagos</p>',
// 3
      '<p>Detalles de pagos realizados</p><p>Estadísticas de pagos y otros egresos por periodos (día, semana, mes, año)</p><p>Estadísticas de pagos por proveedor</p>',
// 4
      '<p>Detalles de ingresos recibidos</p><p>Estadísticas de cobranza y otros ingresos por periodos (día, semana, mes, año)</p><p>Estadísticas de cobranza por cliente o agente</p>',
// 5
      '<p>Conciliaciones bancarias diarias</p><p>Disponibilidad de saldos en cuentas de cheques y créditos revolventes</p><p>Movimientos en transito</p><p>Seguimiento a depósitos no identificados</p><p>Detalles de ingresos y egresos</p>',
// 6
      '<p>Compras clasificadas según necesidades de la empresa</p><p>Estadísticas de compras por periodos (día, semana, mes, año)</p><p>Estadísticas de compras por proveedor</p>',
// 7
      '<p>Ventas clasificadas según necesidades de la empresa</p><p>Estadísticas de venta por periodos (día, semana, mes, año)</p><p>Estadísticas de venta por cliente o agente</p>',
    ]
  };

  handlePopoverOpen(event) {
    this.setState({
      anchorPoint: event.currentTarget,
      anchorPointId: event.currentTarget.id,
      textPopUp: this.state.listTexts[event.currentTarget.id.split('-')[1]-1],
      idPopUp: event.currentTarget.id.split('-')[1]-1
    });
  };

  handlePopoverClose() {
    this.setState({
      anchorPoint: null,
      anchorPointId: null
    });
  };

  render() {
    return (
      <div className="section-office">
        <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 989.78 589.56">
          <defs>
          </defs>
          <title>002_mañanatipica</title>
          <g id="Capa_2" data-name="Capa 2">
            <g id="Layer_1" data-name="Layer 1">
              <polyline className="cls-office-1" points="752.2 374.3 814.01 544.45 804.64 544.45 738.29 387.96" />
              <polyline className="cls-office-1" points="701.95 411.07 725.35 550.05 715.98 550.05 684.66 415.48" />
              <line className="cls-office-1" x1="585.31" y1="535.11" x2="624.51" y2="417.83" />
              <path className="cls-office-1" d="M611.07,256.94c-2.91-39.73,10.48-62.39,36.62-73.23" />
              <path className="cls-office-1" d="M595.64,346.1c2.18-10.37,5.12-21.27,7.87-32.28" />
              <path className="cls-office-1" d="M793.7,302.83C773.11,359,729.2,420.4,654.36,418.28c-15.85-.45-28-2.7-37.06-6.43" />
              <path className="cls-office-1" d="M773.55,190.07c18.73,8.46,34.52,21.92,34.52,43.23,0,12.84-2.59,30.72-8.14,50.33" />
              <polygon className="cls-office-2" points="453.19 249.9 452.99 251.73 364.94 250.39 364.96 249.9 453.19 249.9" />
              <polygon className="cls-office-3" points="454.6 236.94 365.5 236.94 365.54 236.13 454.73 235.66 454.6 236.94" />
              <polygon className="cls-office-3" points="455.61 227.59 455.53 228.3 365.87 228.3 365.89 227.59 455.61 227.59" />
              <polygon className="cls-office-3" points="455.31 230.38 455.1 232.24 365.76 230.89 365.78 230.38 455.31 230.38" />
              <polygon className="cls-office-3" points="365.29 242.1 454.04 242.1 453.93 243.1 365.25 243.1 365.29 242.1" />
              <polygon className="cls-office-3" points="453.49 247.11 453.42 247.81 365.05 247.81 365.08 247.11 453.49 247.11" />
              <polygon className="cls-office-3" points="365.36 240.31 454.23 240.31 454.12 241.31 365.32 241.31 365.36 240.31" />
              <polygon className="cls-office-3" points="345.3 251.73 388 251.73 389.57 231.85 344.74 231.85 345.3 251.73" />
              <polygon className="cls-office-4" points="346.11 241.79 390.1 241.79 390.06 241.06 345.95 240.63 346.11 241.79" />
              <polygon className="cls-office-4" points="344.95 233.37 345.04 234 389.69 234 389.65 233.37 344.95 233.37" />
              <polygon className="cls-office-4" points="345.3 235.88 345.53 237.56 389.81 236.34 389.79 235.88 345.3 235.88" />
              <polygon className="cls-office-4" points="390.35 246.43 346.75 246.43 346.88 247.33 390.4 247.33 390.35 246.43" />
              <polygon className=" cls-office-5" points="347.37 250.94 347.46 251.58 390.63 251.58 390.59 250.94 347.37 250.94" />
              <polygon className="cls-office-4" points="390.26 244.82 346.53 244.82 346.65 245.72 390.31 245.72 390.26 244.82" />
              <polygon className="cls-office-3" points="417.95 230.66 422.78 230.66 426.56 233.83 421.84 233.83 417.95 230.66" />
              <polygon className="cls-office-3" points="420.01 197.65 420.05 197.01 380.31 197.01 380.3 197.65 420.01 197.65" />
              <polygon className="cls-office-3" points="420.24 193.41 420.29 192.51 380.41 192.51 380.39 193.41 420.24 193.41" />
              <polygon className="cls-office-3" points="420.33 191.8 420.38 190.9 380.44 190.9 380.42 191.8 420.33 191.8" />
              <polygon className="cls-office-3" points="420.54 187.86 420.58 187.11 380.52 186.94 380.5 187.86 420.54 187.86" />
              <polygon className="cls-office-3" points="419.88 200.05 419.91 199.53 380.26 199.53 380.24 200.52 419.88 200.05" />
              <polygon className="cls-office-3" points="420.83 182.46 420.86 181.95 380.63 181.95 380.61 182.94 420.83 182.46" />
              <polygon className="cls-office-3" points="380.68 179.44 380.67 180.08 420.96 180.08 421 179.44 380.68 179.44" />
              <polygon className=" cls-office-6" points="418.43 226.95 418.48 226.05 379.71 226.05 379.69 226.95 418.43 226.95" />
              <polygon className=" cls-office-6" points="418.93 217.64 418.96 217.1 379.89 217.1 379.87 218.1 418.93 217.64" />
              <polygon className=" cls-office-6" points="418.64 223.01 418.68 222.25 379.79 222.09 379.77 223.01 418.64 223.01" />
              <polygon className=" cls-office-6" points="418.34 228.56 418.39 227.66 379.67 227.66 379.65 228.56 418.34 228.56" />
              <polygon className="cls-office-3" points="419.38 209.37 419.43 208.47 380.07 208.47 380.06 209.37 419.38 209.37" />
              <polygon className="cls-office-3" points="419.59 205.44 419.63 204.68 380.16 204.52 380.14 205.44 419.59 205.44" />
              <polygon className=" cls-office-6" points="419.06 215.23 419.1 214.59 379.95 214.59 379.93 215.23 419.06 215.23" />
              <polygon className="cls-office-3" points="419.29 210.98 419.34 210.08 380.04 210.08 380.02 210.98 419.29 210.98" />
              <polygon className="cls-office-3" points="345.14 231.18 385.98 231.7 383.12 177.93 343.98 177.41 345.14 231.18" />
              <polygon className="cls-office-4" points="348.45 214.07 348.54 214.7 385.1 215.23 385.07 214.59 348.45 214.07" />
              <polygon className="cls-office-4" points="384.74 208.47 347.61 207.95 347.73 208.85 384.79 209.37 384.74 208.47" />
              <polygon className="cls-office-4" points="384.83 210.08 347.83 209.56 347.95 210.46 384.88 210.98 384.83 210.08" />
              <polygon className="cls-office-4" points="344.49 226.42 385.73 226.95 385.68 226.05 344.36 225.52 344.49 226.42" />
              <polygon className="cls-office-4" points="347.19 204.91 384.58 205.44 384.54 204.71 347.03 203.77 347.19 204.91" />
              <polygon className="cls-office-4" points="348.8 216.58 349.02 218.2 385.23 217.54 385.2 217.1 348.8 216.58" />
              <polygon className="cls-office-4" points="343.94 222.49 385.52 223.01 385.48 222.29 343.79 221.35 343.94 222.49" />
              <polygon className="cls-office-4" points="385.76 227.66 344.58 227.13 344.71 228.03 385.81 228.56 385.76 227.66" />
              <polygon className="cls-office-4" points="346.37 199 346.6 200.65 384.29 199.97 384.27 199.53 346.37 199" />
              <polygon className="cls-office-4" points="345.35 187.34 383.64 187.86 383.61 187.13 345.19 186.18 345.35 187.34" />
              <polygon className="cls-office-4" points="344.19 178.91 344.28 179.55 383.23 180.08 383.2 179.44 344.19 178.91" />
              <polygon className="cls-office-4" points="344.54 181.43 344.77 183.11 383.36 182.41 383.33 181.95 344.54 181.43" />
              <polygon className="cls-office-4" points="383.89 192.51 345.4 191.98 345.53 192.88 383.94 193.41 383.89 192.51" />
              <polygon className="cls-office-4" points="346.02 196.49 346.11 197.13 384.17 197.65 384.13 197.01 346.02 196.49" />
              <polygon className="cls-office-4" points="383.81 190.9 345.18 190.37 345.31 191.27 383.86 191.8 383.81 190.9" />
              <polygon className="cls-office-3" points="392.22 182.94 397.05 182.94 400.83 186.12 396.11 186.12 392.22 182.94" />
              <polygon className="cls-office-3" points="397.59 217.91 402.29 217.91 405.24 221.88 400.54 221.88 397.59 217.91" />
              <polygon className="cls-office-3" points="474.86 226.06 474.88 225.59 449.65 225.59 449.64 226.06 474.86 226.06" />
              <polygon className="cls-office-3" points="475.04 222.88 475.08 222.21 449.73 222.21 449.71 222.88 475.04 222.88" />
              <polygon className="cls-office-3" points="471.29 221.68 471.33 221 449.75 221 449.74 221.68 471.29 221.68" />
              <polygon className="cls-office-3" points="471.46 218.73 471.49 218.17 449.82 218.04 449.8 218.73 471.46 218.73" />
              <polygon className="cls-office-3" points="474.75 227.86 474.77 227.47 449.61 227.47 449.59 228.22 474.75 227.86" />
              <polygon className="cls-office-3" points="471.69 214.68 471.71 214.3 449.9 214.3 449.89 215.04 471.69 214.68" />
              <polygon className="cls-office-3" points="449.94 212.42 449.93 212.9 471.8 212.9 471.82 212.42 449.94 212.42" />
              <polygon className="cls-office-3" points="480.06 248.02 480.1 247.34 449.17 247.34 449.15 248.02 480.06 248.02" />
              <polygon className="cls-office-3" points="480.46 241.04 480.48 240.64 449.32 240.64 449.3 241.39 480.46 241.04" />
              <polygon className="cls-office-3" points="480.23 245.07 480.26 244.5 449.23 244.38 449.22 245.07 480.23 245.07" />
              <polygon className="cls-office-3" points="479.99 249.22 480.03 248.55 449.14 248.55 449.13 249.22 479.99 249.22" />
              <polygon className="cls-office-3" points="478.3 234.85 478.34 234.17 449.46 234.17 449.45 234.85 478.3 234.85" />
              <polygon className="cls-office-3" points="478.47 231.9 478.5 231.33 449.53 231.21 449.51 231.9 478.47 231.9" />
              <polygon className="cls-office-3" points="480.56 239.23 480.59 238.76 449.36 238.76 449.35 239.23 480.56 239.23" />
              <polygon className="cls-office-3" points="478.23 236.05 478.27 235.38 449.43 235.38 449.42 236.05 478.23 236.05" />
              <polygon className="cls-office-3" points="373.79 238.76 373.86 239.23 453.47 239.23 453.44 238.76 373.79 238.76" />
              <polygon className="cls-office-3" points="453.19 234.17 373.11 234.17 373.21 234.85 453.22 234.85 453.19 234.17" />
              <polygon className="cls-office-3" points="453.25 235.38 373.29 235.38 373.39 236.05 453.29 236.05 453.25 235.38" />
              <polygon className="cls-office-3" points="375.15 248.02 453.97 248.02 453.93 247.34 375.05 247.34 375.15 248.02" />
              <polygon className="cls-office-3" points="372.78 231.9 453.06 231.9 453.02 231.35 372.65 231.04 372.78 231.9" />
              <polygon className="cls-office-3" points="374.06 240.64 374.24 241.85 453.57 240.97 453.55 240.64 374.06 240.64" />
              <polygon className="cls-office-3" points="374.71 245.07 453.8 245.07 453.77 244.53 374.59 244.22 374.71 245.07" />
              <polygon className="cls-office-3" points="454 248.55 375.22 248.55 375.32 249.22 454.04 249.22 454 248.55" />
              <polygon className="cls-office-3" points="372.13 227.47 372.31 228.71 452.82 227.8 452.81 227.47 372.13 227.47" />
              <polygon className="cls-office-3" points="370.85 218.73 452.31 218.73 452.28 218.18 370.72 217.86 370.85 218.73" />
              <polygon className="cls-office-3" points="369.92 212.42 369.99 212.9 451.98 212.9 451.95 212.42 369.92 212.42" />
              <polygon className="cls-office-3" points="370.2 214.3 370.38 215.56 452.08 214.64 452.06 214.3 370.2 214.3" />
              <polygon className="cls-office-3" points="452.51 222.21 371.36 222.21 371.46 222.88 452.55 222.88 452.51 222.21" />
              <polygon className="cls-office-3" points="371.85 225.59 371.92 226.06 452.73 226.06 452.7 225.59 371.85 225.59" />
              <polygon className="cls-office-3" points="452.44 221 371.18 221 371.28 221.68 452.48 221.68 452.44 221" />
              <polygon className="cls-office-3" points="459.15 215.04 463 215.04 466.02 217.42 462.25 217.42 459.15 215.04" />
              <polygon className="cls-office-3" points="463.43 241.25 467.18 241.25 469.54 244.22 465.79 244.22 463.43 241.25" />
              <polygon className="cls-office-3" points="380.68 225.57 407.77 225.57 400.07 238.19 380.68 225.57" />
              <rect className="cls-office-1" x="280.16" y="160.37" width="34.85" height="125.26" />
              <polygon className="cls-office-3" points="280.8 285.63 280.8 160.37 283.5 160.37 283.5 283.81 280.8 285.63" />
              <ellipse className="cls-office-1" cx="297.58" cy="253.22" rx="9.81" ry="9.94" />
              <ellipse className="cls-office-3" cx="297.58" cy="253.22" rx="7.91" ry="8.01" />
              <path className="cls-office-1"
                d="M572.74,529.72c1.55,6.17,2.87,11.62,3.87,16,.94,4.17.42,7-2.21,10.44-7.51,9.67-4.81,17.71-13.39,19.63l1.49.78s7.92,3.12,13.44-.85c0,0,6.31-12.14,11.73-17.5,0,0,3.92-2.24-1.4-10.57a5.06,5.06,0,0,1-1.07-3.24c.36-4.22,0-19-.72-36.19" />
              <path className="cls-office-1"
                d="M577,431.28c-3.49-9.23-8.16-19-13.61-26.35l23.48-9.28-37-13.42-8.71,4.9a15.76,15.76,0,0,0-4.19,3.42,11.76,11.76,0,0,0-.37,3,21.14,21.14,0,0,0-.12,2.23c.06.28.13.57.21.85,3.47,12.36,16.69,59.61,27.38,99.8" />
              <path className="cls-office-3" d="M592.78,556.49s1.12-4.58-6.95-9.65c0,0-4.5,8.25-1.08,14.42Z" />
              <path className="cls-office-3"
                d="M579.75,571.24s-4.43,8-5.15,11.63l-20.14-3.59s4-4.77,7.53-4.67C562,574.61,572.29,576.78,579.75,571.24Z" />
              <path className="cls-office-3"
                d="M553.71,580.71l.75-1.43s15.11,2.36,20,1.51a40.76,40.76,0,0,1,4.34-11h0c2.64-4.66,8.48-13,14.07-13.91,0,0-3.57,9.15-2.31,24.46l-1.8.45s.32-13.28-2.63-17.39c0,0-.28.08-2.07,2-.08.1-.15.18-.22.28a46.1,46.1,0,0,0-4.35,7.66,62.17,62.17,0,0,0-3.49,8.88,1.52,1.52,0,0,1-1.68,1.13Z" />
              <path className="cls-office-1"
                d="M613,411.07l-27.83-14.24s-.5,1.69-3.61,10.14l-.13.07c0,.12,0,.25,0,.38s-.08.22-.13.34,0,.27,0,.41c-2.86,24.37-10.17,73.59-26.85,126.55a57.09,57.09,0,0,1-13.35,22.47,47.8,47.8,0,0,0-5.37,6.59c-7.7,11.63-5.2,8.3-15.26,12.1l15.89,5.63s6.68-14.85,19.22-14.35c0,0,6.76-7.21,2.73-14.3,0,0,26.68-49,36.68-70.07,9.55-20.1,14.66-47.33,12-64.64Z" />
              <path className="cls-office-1" d="M728.32,267.92c-2.11-7.43-3.78-12.3-3.78-12.3" />
              <path className="cls-office-1"
                d="M647.17,301.72q-2,2.4-4.32,4.83c-22.44,24-53.79,46.81-93,75.68,0,0,36,12.93,63.1,28.84,0,0,42.21-16.93,64.74-28.29s49.33-27.31,57-51.16c3.94-12.18,1.51-29.37-2.13-45.6" />
              <path className="cls-office-1" d="M671.38,257.69S670,265,664.51,275.91" />
              <path className="cls-office-3" d="M560.23,567.38s3.5-3.19-.37-11.85c0,0-8.36,4.38-9,11.4Z" />
              <path className="cls-office-3"
                d="M541.71,574.72s-6.12,6.6-7.61,10l-18.83-7.49s4.93-3.83,8.37-3C523.64,574.26,533.23,578.56,541.71,574.72Z" />
              <path className="cls-office-3"
                d="M514.24,578.47l1-1.23s13.56,5.38,18.52,5.57c0,0,5.92-10.35,11.93-13.28s9.09-5.25,14.91-2.63a65.73,65.73,0,0,0-15.1,22.66l-1.75-.62s7.19-14.46,7-19.5c0,0-3.71-.92-9.81,7.28a59.12,59.12,0,0,0-5.38,8,1.39,1.39,0,0,1-1.7.68Z" />
              <path className="cls-office-1" d="M585.91,264.2c14.72-2.63,28.05-5.78,37.76-11" />
              <path className="cls-office-1" d="M638.27,270.29c-6.08,2.27-16.07,4.17-27.53,5.75" />
              <path className="cls-office-1" d="M619.21,255.32c19.28-19.6,34-86,34-86q.35-1.77.78-3.33" />
              <path className="cls-office-1" d="M670.61,206.13c-15,31.51-30.05,61.54-30.05,61.54-3.37,3.62-1.79,2.22-2.29,2.62" />
              <path className="cls-office-1" d="M755.47,154.87s-25-18.83-31.31-28.12" />
              <path className="cls-office-1" d="M723.29,245.1c1.26-9,3.84-25.21,6.93-32.88,1.94-4.82,6.81-14.49,11.77-24.7" />
              <path className="cls-office-1"
                d="M678.89,144.5c-6,12.81-12.69,28.79-20.27,39.87,0,0-1.93,11.47,12.39,20.2,0,0-.38,23.77.11,40.16" />
              <polyline className="cls-office-1" points="703.23 123.2 695.52 145.59 691.49 126.21" />
              <path className="cls-office-3"
                d="M684.78,249.38l1.59-43.63a.76.76,0,0,0-.2-.56c-4.34-5-4.89-17.4-5-18.51l14.31-41.09-.43-1.81L679.6,186.27a.65.65,0,0,0-.05.31c0,.33.27,13.57,5.16,19.43l-1.58,43.32Z" />
              <path className="cls-office-3" d="M717.83,202.8s-8.59,2.73-20.27,0C697.56,202.8,706.12,211.13,717.83,202.8Z" />
              <path className="cls-office-1"
                d="M717.88,114.83c-3.58,1.53-9.92,4.53-14.65,8.37A148.35,148.35,0,0,0,704.74,161s10.19-22.75,18.91-30.49" />
              <path className="cls-office-1"
                d="M689.87,116l2.07,7.37s-4.75,18.84-6,31.95c0,0-2.07-16.59-3.1-20.69C682.85,134.6,687.4,118.63,689.87,116Z" />
              <path className="cls-office-1"
                d="M671,246.62s-1.19,4.31.37,11.07c0,0,16,8.16,53.16-2.07l-1.46-9S697.18,253.38,671,246.62Z" />
              <path className="cls-office-1"
                d="M762.71,260.77c-1.79-40.32-19-74.52-19-74.52-6.56-9.3-6.59-15.88-6-20.93a42.48,42.48,0,0,1,.84-5c2.18-9.39,14.93-10.8,19.28-2.22,27.74,54.74,29.41,101.26,29.35,101.9" />
              <path className="cls-office-1" d="M657.45,277.69l4.44-1.18A78.34,78.34,0,0,0,686,280" />
              <path className="cls-office-1"
                d="M787.25,259.94c2,4.12-.68,9.92-7,15.48-22.75,10.25-91,12.12-91,12.12A8.11,8.11,0,0,1,686,280c27.46-8.36,55.39-12.12,75.43-21.32" />
              <path className="cls-office-1"
                d="M683,137.35a13.15,13.15,0,0,1-1.38,2.25c-6.52,10.05-21.2,23-12.86,37a25.43,25.43,0,0,1-4.89-7.33" />
              <path className="cls-office-1" d="M692.67,96.38s1.46,5.49-1.28,17.34" />
              <path className="cls-office-1"
                d="M668.88,97.32c-8.07,7.54-21.19,22.39-21.19,39.21,0,19.94,6.46,28.27,17.88,40.06,0,0-10.43-19.42.83-34.34S687.86,115.91,690,97.32" />
              <path className="cls-office-1"
                d="M676.9,27.13c-3.67,3.82-8.64,9.34-10.73,13.26,0,0,3.38,10.23-8,17,0,0-5.88,23.47,15.62,42.88,0,0,22,2.53,41-18" />
              <path className="cls-office-1" d="M722.06,62.16s6.26-5.47,9.38-4.14,3.23,17-15.19,20.11" />
              <path className="cls-office-1"
                d="M718.2,96.52c.51,4.85,1,8.79,1.31,11.31,1.74,14.28,9.34,22,9.34,22,9.39-9.71,37.43-37.32,27.82-69.8C750.86,40.33,741,30.73,734.19,26.21c-.42-4.24-3-18.48-19.42-20.9C686.7,1.17,672.4,23.16,672.4,23.16a166.78,166.78,0,0,0,47.3,9.64c-2.46,7.32-3.4,17.23-3.52,27.53,0,0,.17,2.88,4.07,2.92" />
              <path className="cls-office-1"
                d="M723.87,74.57a.51.51,0,0,1,.5-.22.53.53,0,0,1,.43.33c1.67,4.32,8,22.41-3.24,20.75C711.33,93.92,721.31,78.32,723.87,74.57Z" />
              <path className="cls-office-3"
                d="M677.87,52.82s-1.15,13.07-8.1,17.43l3.87,4.34s-4.22-2.58-5.57-4.4C668.07,70.19,675.37,65.05,677.87,52.82Z" />
              <path className="cls-office-3" d="M701.3,57c-.07.56-2.41.74-5.25.42s-5.08-1-5-1.59,2.3.26,5.13.58S701.36,56.48,701.3,57Z" />
              <path className="cls-office-3"
                d="M673.35,54.14c-.11.55-2.47.52-5.26-.06s-5-1.5-4.85-2,2.27.47,5.06,1.06S673.47,53.59,673.35,54.14Z" />
              <path className="cls-office-3"
                d="M681.39,83.16s-10.76,4-14.21-2.59c0,0,8.35,2.92,13.65.76C680.83,81.33,682.08,82.06,681.39,83.16Z" />
              <rect className="cls-office-1" x="317.29" y="160.37" width="27.53" height="125.26" />
              <polygon className="cls-office-3" points="317.93 285.63 317.93 160.37 320.63 160.37 320.63 283.81 317.93 285.63" />
              <ellipse className="cls-office-1" cx="331.05" cy="253.22" rx="9.81" ry="9.94" />
              <ellipse className="cls-office-3" cx="331.05" cy="253.22" rx="7.91" ry="8.01" />
              <polygon className="cls-office-1" points="460.11 285.63 347.1 285.63 347.1 251.2 456.06 251.2 460.11 285.63" />
              <polygon className="cls-office-3" points="460.11 285.63 347.1 285.63 347.1 282.96 458.1 282.96 460.11 285.63" />
              <polyline className="cls-office-1" points="486.07 251.2 456.06 251.2 460.11 285.63 511.37 285.63" />
              <rect className="cls-office-3" x="459.32" y="259.71" width="29.66" height="1.23" />
              <rect className="cls-office-3" x="460.86" y="262.97" width="28.11" height="1.23" />
              <rect className="cls-office-3" x="462.35" y="269.59" width="29.59" height="1.23" />
              <rect className="cls-office-3" x="462.35" y="276.64" width="31.74" height="0.97" />
              <polygon className="cls-office-3" points="474.63 270.81 487.23 270.81 492.8 274.07 478.53 275.15 474.63 270.81" />
              <polygon className="cls-office-3" points="466.2 263.58 471.88 263.58 477.44 266.85 470.1 267.92 466.2 263.58" />
              <circle className="cls-office-1" cx="434.54" cy="269.03" r="7.98" />
              <path className="cls-office-3" d="M441,269a6.43,6.43,0,1,1-6.43-6.43A6.43,6.43,0,0,1,441,269Z" />
              <rect className="cls-office-1" x="351.4" y="260.94" width="60.42" height="16.18" />
              <rect className="cls-office-3" x="370.23" y="260.94" width="5.42" height="16.18" />
              <rect className="cls-office-3" x="384.63" y="260.94" width="0.98" height="16.18" />
              <rect className="cls-office-3" x="390.22" y="260.94" width="0.98" height="16.18" />
              <rect className="cls-office-3" x="395.82" y="260.94" width="0.98" height="16.18" />
              <polygon className="cls-office-3" points="511.99 285.63 458.13 285.63 457.84 282.96 511.04 282.96 511.99 285.63" />
              <polygon className="cls-office-3" points="428.1 574.33 415.85 574.33 432.63 308.83 440.35 308.83 428.1 574.33" />
              <polygon className="cls-office-3" points="327.91 574.33 315.66 574.33 332.44 308.83 340.16 308.83 327.91 574.33" />
              <polygon className="cls-office-3" points="428.1 574.33 320.36 574.33 320.36 565.94 420.55 565.94 428.1 574.33" />
              <rect className="cls-office-3" x="353.46" y="301.34" width="18.12" height="9.82" />
              <rect className="cls-office-3" x="479.39" y="301.34" width="18.12" height="9.82" />
              <rect className="cls-office-3" x="332.44" y="304.87" width="249.67" height="7.93" />
              <polygon className="cls-office-3" points="668.6 574.33 680.85 574.33 664.07 308.83 656.35 308.83 668.6 574.33" />
              <polygon className="cls-office-3" points="768.78 574.33 781.03 574.33 764.25 308.83 756.53 308.83 768.78 574.33" />
              <polygon className="cls-office-3" points="668.6 574.33 776.34 574.33 776.34 565.94 676.15 565.94 668.6 574.33" />
              <rect className="cls-office-3" x="725.11" y="301.34" width="18.12" height="9.82" />
              <rect className="cls-office-3" x="599.18" y="301.34" width="18.12" height="9.82" />
              <rect className="cls-office-3" x="572.94" y="304.87" width="191.31" height="7.93" />
              <rect className="cls-office-1" x="287.81" y="284.98" width="521.08" height="16.35" />
              <rect className="cls-office-3" x="287.81" y="284.98" width="521.08" height="4.09" />
              <path className="cls-office-1" d="M552.8,192.7h5.29a5.19,5.19,0,0,1,5,3.67l27.13,88.71H586.9" />
              <path className="cls-office-1"
                d="M469.33,189.71a4.59,4.59,0,0,0-4.48,6.14l32,89.23H586.9l-2.94-9.5-25.83-79.42a5.19,5.19,0,0,0-4.76-3.58Z" />
              <polygon className="cls-office-1" points="590.18 285.08 676.17 285.08 671.8 279.3 587.32 275.72 590.18 285.08" />
              <rect className="cls-office-1" x="290.28" y="171.44" width="16.18" height="60.42" />
              <rect className="cls-office-3" x="290.28" y="207.6" width="16.18" height="5.42" />
              <rect className="cls-office-3" x="290.28" y="197.65" width="16.18" height="0.98" />
              <rect className="cls-office-3" x="290.28" y="192.05" width="16.18" height="0.98" />
              <rect className="cls-office-3" x="290.28" y="186.45" width="16.18" height="0.98" />
              <rect className="cls-office-1" x="324.36" y="171.44" width="16.18" height="60.42" />
              <rect className="cls-office-3" x="324.36" y="207.6" width="16.18" height="5.42" />
              <rect className="cls-office-3" x="324.36" y="197.65" width="16.18" height="0.98" />
              <rect className="cls-office-3" x="324.36" y="192.05" width="16.18" height="0.98" />
              <rect className="cls-office-3" x="324.36" y="186.45" width="16.18" height="0.98" />
              <text
                id="option-2"
                className={`cls-office-7 btn-option text-point ${this.state.anchorPointId === 'option-2'? 'active' : ''}`}
                transform="translate(103.81 115.72)"
                onClick={(event) => this.handlePopoverOpen(event) }>
                <tspan className=" cls-office-8">C</tspan>
                <tspan x="14.02" y="0">uentas</tspan>
                <tspan className=" cls-office-9" x="82.8" y="0"> </tspan>
                <tspan className={`cls-office-10 ${this.state.anchorPointId === 'option-2'? 'active' : ''}`}>
                  <tspan x="-6.88" y="24">por </tspan>
                  <tspan className=" cls-office-11" x="34.3" y="24">p</tspan>
                  <tspan className=" cls-office-12" x="47.92" y="24">a</tspan>
                  <tspan x="60.38" y="24">gar</tspan>
                </tspan>
              </text>
              <text
                id="option-1"
                className={`cls-office-13 btn-option text-point ${this.state.anchorPointId === 'option-1'? 'active' : ''}`} transform="translate(11.18 269.92)"
                onClick={(event) => this.handlePopoverOpen(event) }>
                <tspan className=" cls-office-14">C<tspan className=" cls-office-15" x="14.12" y="0">uentas</tspan>
                  <tspan className=" cls-office-9" x="83.78" y="0"> </tspan>
                </tspan>
                <tspan className={`cls-office-10 ${this.state.anchorPointId === 'option-1'? 'active' : ''}`}>
                  <tspan x="-11.18" y="24">por </tspan>
                  <tspan className=" cls-office-16" x="30" y="24">C</tspan>
                  <tspan className=" cls-office-17" x="44.28" y="24">ob</tspan>
                  <tspan className=" cls-office-18" x="71.18" y="24">r</tspan>
                  <tspan x="79.66" y="24">ar</tspan>
                </tspan>
              </text>
              <text
                id="option-3"
                className={`cls-office-19 btn-option text-point ${this.state.anchorPointId === 'option-3'? 'active' : ''}`}
                transform="translate(321.24 17)"
                onClick={(event) => this.handlePopoverOpen(event) }>
                <tspan className=" cls-office-20">P</tspan>
                <tspan className=" cls-office-12" x="14.34" y="0">a</tspan>
                <tspan x="26.8" y="0">gos</tspan>
              </text>
              <text
                id="option-4"
                className={`cls-office-19 btn-option text-point ${this.state.anchorPointId === 'option-4'? 'active' : ''}`}
                transform="translate(464.97 80.13)"
                onClick={(event) => this.handlePopoverOpen(event) }>
                <tspan className=" cls-office-16">C</tspan>
                <tspan x="14.28" y="0">ob</tspan>
                <tspan className=" cls-office-21" x="41.18" y="0">r</tspan>
                <tspan x="49.66" y="0">anza</tspan>
              </text>
              <text
                id="option-5"
                className={`cls-office-19 btn-option text-point ${this.state.anchorPointId === 'option-5'? 'active' : ''}`}
                transform="translate(808.89 41.44)"
                onClick={(event) => this.handlePopoverOpen(event) }>
                <tspan className=" cls-office-22">T</tspan>
                <tspan className=" cls-office-17" x="11.26" y="0">eso</tspan>
                <tspan className=" cls-office-23" x="47.6" y="0">r</tspan>
                <tspan x="56.04" y="0">e</tspan>
                <tspan className=" cls-office-24" x="68.66" y="0">r</tspan>
                <tspan className=" cls-office-17" x="77.2" y="0">ía</tspan>
              </text>
              <text
                id="option-7"
                className={`cls-office-19 btn-option text-point ${this.state.anchorPointId === 'option-7'? 'active' : ''}`}
                transform="translate(917.86 280.28)"
                onClick={(event) => this.handlePopoverOpen(event) }>
                <tspan className=" cls-office-25">V</tspan>
                <tspan x="13.82" y="0">entas</tspan>
              </text>
              <text
                id="option-6"
                className={`cls-office-19 btn-option text-point ${this.state.anchorPointId === 'option-6'? 'active' : ''}`}
                transform="translate(888.51 177.88)"
                onClick={(event) => this.handlePopoverOpen(event) }>
                <tspan className=" cls-office-16">C</tspan>
                <tspan x="14.28" y="0">omp</tspan>
                <tspan className=" cls-office-18" x="62.16" y="0">r</tspan>
                <tspan x="70.64" y="0">as</tspan>
              </text>
              <path className={`cls-office-3 line-point ${this.state.anchorPointId === 'option-2'? 'active' : ''}`}
                d="M159.84,157.25c9.48,51.59,53.85,97,107.2,101.26,3.23.26,2.94-4.14-.08-4.38C215.6,250,173.68,205.78,164.6,156.39c-.49-2.71-5.31-2.16-4.76.86Z" />
              <path className={`cls-office-3 line-point ${this.state.anchorPointId === 'option-3'? 'active' : ''}`}
                d="M357.26,28.32c23.12,38.8,13.37,87.05-5.61,125.27-.6,1.21,2.5,4.1,2.33,4.43,20.49-41.26,27.94-90,3.36-131.24-1.78-3-1,.08-.08,1.54Z" />
              <path className={`cls-office-3 line-point ${this.state.anchorPointId === 'option-4'? 'active' : ''}`}
                d="M477.16,86.48a201,201,0,0,1-38,112.66c-1.46,2,1.84,5.74,3.45,3.53A204.88,204.88,0,0,0,481.38,88c.06-2.48-4.14-4.63-4.22-1.52Z" />
              <path className={`cls-office-3 line-point ${this.state.anchorPointId === 'option-1'? 'active' : ''}`}
                d="M122.07,292.29c18.14,19.09,46.64,23.12,71.73,23.86a214.41,214.41,0,0,0,85.84-15.38c1.63-.65,3.89-4.36.89-3.16a209.52,209.52,0,0,1-83.87,15.08c-24.57-.72-52.68-4.52-70.47-23.24-1.24-1.29-5.2,1.7-4.12,2.84Z" />
              <path className={`cls-office-3 line-point ${this.state.anchorPointId === 'option-5'? 'active' : ''}`}
                d="M826.78,51.87c-1,37.17-19.91,71.34-46.4,96.68-29.73,28.43-68.66,45-107.76,56-25.59,7.21-52.45,14-79.22,12.19-1-.07-4.88,1.43-4.09,1.49,23.14,1.59,46-3.17,68.32-8.71,20.67-5.13,41.11-11.36,60.74-19.65,37.86-16,72.65-40.54,94.14-76.25C824,94.58,831,73,831.64,50.7c0-.32-4.83.1-4.86,1.17Z" />
              <path className={`cls-office-3 line-point ${this.state.anchorPointId === 'option-6'? 'active' : ''}`}
                d="M877.17,174.41c-23.7-10.87-49.23-16.47-74.78-21-25.14-4.44-50.49-7.78-75.94-9.72-51.33-3.91-103.36-2.23-153.89,8a422.48,422.48,0,0,0-81.45,25.13c-.7.3,3,2.83,4.28,2.27,93.22-39.65,197.71-41.46,296.46-25.53,28.81,4.65,58,10,84.69,22.28,2.65,1.21,3.29-.26.63-1.48Z" />
              <path className={`cls-office-3 line-point ${this.state.anchorPointId === 'option-7'? 'active' : ''}`}
                d="M911.89,271.47c-42,.78-83.83.88-125.59-4.48-40.21-5.15-79.94-13.43-120.12-18.77A541.33,541.33,0,0,0,599,243.1c-3.27,0-.43,3.15,1.64,3.16,81.46.6,160.36,25.56,241.69,28.42,23.72.84,47.47.39,71.2-.05,3.26-.06.44-3.2-1.64-3.16Z" />
            </g>
          </g>
        </svg>
        </div>
        <div>
          <Popover
            id="mouse-over-popover"
            anchorEl={this.state.anchorPoint}
            open={Boolean(this.state.anchorPoint)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClose={() => this.handlePopoverClose()}
            disableRestoreFocus
          >
            <div
              className={`container-text-popup circle-${this.state.idPopUp}`}
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.textPopUp) }}>
            </div>
          </Popover>
        </div>
      </div>
    )
  }
}

export default OfficeSvg;