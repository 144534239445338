import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";

import './Footer.scss';

import Container from '@material-ui/core/Container';

class Footer extends Component {

  render() {
    return (
      <footer>
        <Container maxWidth="xl">
          <div className="flex-space-between">
            <div className="container-footer-info">
              © 2020 Contacrece
            </div>
            <div className="container-footer-info">
              <Link to="/aviso-de-privacidad">Privacidad</Link>
            </div>
          </div>
        </Container>
      </footer>
    )
  } 
}

export default Footer;