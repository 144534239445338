import React, { Component } from 'react';
import renderHTML from 'react-render-html';

import './SectionPosts.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

class SectionPosts extends Component {

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  unescapeHTML(escapedHTML) {
    return escapedHTML.replace(/&lt;/g,'<').replace(/&gt;/g,'>').replace(/&amp;/g,'&');
  }

  render() {
    return (
      <div className="section-post">
        { this.props.post.text && 
          <div
            className="card-posts"
            data-aos="fade-up"
            data-aos-once="true">

            <div className="container-posts-list">

              <div className="posts">

                <div
                  className="content-socialnetwork mt-4"
                  data-aos="fade-up"
                  data-aos-once="true">
                  <div className="title-follow">

                  </div>
                  <div className="icon-socialNetwork">
                    <a href={`https://www.linkedin.com/shareArticle?mini=true&url=https://contacrece.com/blog/${this.props.post.url}&title=${this.props.post.title}&summary=${this.props.post.summary}`}  target="_blank" rel="noopener noreferrer">
                      <img src="/img/012_linkedin.svg" alt=""/>
                    </a>
                    <a href={`https://twitter.com/intent/tweet?url=https://contacrece.com/blog/${this.props.post.url}&text=${this.props.post.title}`} target="_blank" rel="noopener noreferrer">
                      <img src="/img/013_twitter.svg" alt=""/>
                    </a>
                  </div>
                </div>

                <div
                  className="createdBy mt-2 mb-5">
                  Hecho por &nbsp; / &nbsp; { renderHTML(this.props.post.created_by ? this.props.post.created_by : '') } &nbsp; / &nbsp; Empresa
                </div>

                <div
                  className="text"
                  data-aos="fade-up"
                  data-aos-once="true">
                  {renderHTML(this.unescapeHTML(this.props.post.text ? this.props.post.text : ''))}
                </div>
              </div>

            </div>
          </div>
        }
      </div>
    )
  } 
}

export default SectionPosts;