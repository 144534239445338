const dev = {
  URL: {
    SERVER: "http://localhost/api",
    BASE: "http://localhost"
  }
};

const prod = {
  URL: {
    SERVER: "https://contacrece.com/api",
    BASE: "https://contacrece.com"
  }
};

let config = '';
if (process.env.REACT_APP_STAGE === 'prod') {
  config = prod
} else {
  config = dev
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};