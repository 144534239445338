
import React, { Component } from 'react';
import { List, Datagrid, TextField, CardActions, CreateButton, RefreshButton, EditButton, ShowButton } from 'react-admin';
import { Link } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';


const PostPagination = ({ page, perPage, total, setPage }) => {
  const nbPages = Math.ceil(total / perPage) || 1;
  return (
    nbPages > 1 &&
      <Toolbar>
        {page > 1 &&
          <Button color="primary" key="prev" icon={<ChevronLeft />} onClick={() => setPage(page - 1)}>
            Anterior
          </Button>
        }
        {page !== nbPages &&
          <Button color="primary" key="next" icon={<ChevronRight />} onClick={() => setPage(page + 1)} labelPosition="before">
            Siguiente
          </Button>
        }
      </Toolbar>
  );
}

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
      {bulkActions && React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
      })}
      {filters && React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
      }) }
      <CreateButton basePath={basePath} />
      <RefreshButton />
  </CardActions>
);

function BtnLink(props) {
  console.log(props);
  return (
    <ShowButton />
  );
}

class ComponentList extends Component {
  constructor(props){
    super(props);
    console.log(this.props);
  }

  render() {
    return (
      <List
        {...this.props}
        title="Lista de componentes"
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<PostPagination />}
        actions={<PostActions />}>
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="idName" />
          <TextField source="created_at" />
          <TextField source="updated_at" />
          <EditButton basePath={'dataComponents'} />
        </Datagrid>
    </List>
    );
  }
}

export default ComponentList;