import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Link
} from "react-router-dom";
import { Link as LinkLanding } from 'react-scroll';

import './NavBar.scss';

import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

class NavBar extends Component {

  state = {
    right: false
  }

  isHome() {
    return (this.props.location.pathname === '/');
  }

  render() {
    const toggleDrawer = (side, open) => event => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      this.setState({ ...this.state, [side]: open });
    };

    const open = (side) => event => {
      this.setState({
        ...this.state,
        [side]: !this.state.right
      });
    };

    return (
      <div className="container-navbar">
        <Fab
          className={`btn-icon-menu ${(this.state.right ? 'close': '')}`}
          aria-label="add"
          onClick={open('right')}
        >
          <img
            className="icon-show-menu"
            src="/img/002_menu_1.svg" alt=""/>
        </Fab>
        <SwipeableDrawer
          anchor="right"
          open={this.state.right}
          className="navBar-right"
          onClose={toggleDrawer('right', false)}
          onOpen={toggleDrawer('right', true)}
        >
          <div className="container-links-navbar" role="presentation" onClick={toggleDrawer('right', false)} onKeyDown={toggleDrawer('right', false)}
          >
            <div className="container-close-menu"></div>
            <List>
              <div>
                <Link to="/">
                  <ListItem button>
                    {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                    <ListItemText primary={'Inicio'} />
                  </ListItem>
                </Link>
                <Link to="/quienes-somos">
                  <ListItem button>
                    {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                    <ListItemText primary={'Quienes somos'} />
                  </ListItem>
                </Link>
                <div className="split-section">
                  - Blindaje Contacrece -
                </div>
                <div className="container-menu-secundary">
                  <Link to="mañana-tipica">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'Mañana Típica Contacrece'} />
                    </ListItem>
                  </Link>
                  <Link to="/contraloria-progresiva">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'Contraloría Progresiva'} />
                    </ListItem>
                  </Link>
                  <Link to="clase-mundial">
                    <ListItem button>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={'Contabilidad de Clase Mundial'} />
                    </ListItem>
                  </Link>
                </div>
              </div>
            </List>
            <Divider />
            <List className="list-secondary-pages">
              <Link to="/aviso-de-privacidad">
                <ListItem button>
                  {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                  <ListItemText className="sub-menu" primary={'Aviso de privacidad'} />
                </ListItem>
              </Link>
              <a href="/#contact">
                <ListItem button>
                  {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                  <ListItemText className="sub-menu" primary={'Contacto'} />
                </ListItem>
              </a>
            </List>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

export default withRouter(NavBar);