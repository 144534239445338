import React, { Component } from 'react';

import './SectionHeaderStories.scss';

import Header from '../header/Header';
import Footer from './../footer/Footer';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Container from '@material-ui/core/Container';

class SectionHeaderStories extends Component {

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div>
        <div className="section-header-stories">
          <div
            className="section-background"
            style={{
              backgroundImage: 'url("/img/025_background_blog.jpg")'
            }}>
            <Container maxWidth="xl">
              <Header />
            </Container>
            <div className="container-text">
              <div
                className="section-text-center"
                data-aos="fade-up"
                data-aos-once="true">
                  <h1>
                    Acompañamiento <br/>
                    empresarial
                  </h1>
                  <h2>
                    Un blog de Contacrece©
                  </h2>
              </div>
            </div>
          </div>
          {this.props.children}
        </div>
        <Footer />
      </div>
    )
  } 
}

export default SectionHeaderStories;