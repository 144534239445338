import React from 'react';
import { Edit, SimpleForm, DisabledInput, TextInput, BooleanInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import UploadImages from './../uploadImages/uploadFiles';

export const PostEdit = (props) => (
  <div>
    <div className="mb-3">
      <UploadImages />
    </div>
    <Edit title={'Editar posts'} {...props}>
    <SimpleForm>
      <DisabledInput label="Id" source="id" />
      <TextInput label="Nombre" source="name" />
      <TextInput label="URL *" source="url" />
      <TextInput label="Autor *" source="created_by" />
      <TextInput label="Sitio *" source="created_site" />

      <TextInput label="Titulo *" source="title" />
      <TextInput label="Imagen redes sociales *" source="socialNetworkImage" />
      <RichTextInput label="Resumen *" source="summary" />
      <RichTextInput label="Post *" source="text" />

      <BooleanInput label="Publicar" source="show" />
    </SimpleForm>
  </Edit>
  </div>
);