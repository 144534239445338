import React, { Component } from 'react';

import './WhatTypicalMorning.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Container from '@material-ui/core/Container';

import { getDataComponentByIdName, replaceNewLine } from '../../utils/getDataComponents';

class WhatTypicalMorning extends Component {

  state = {
    dataComponent: [
      {
        'id': 'section1',
        'name': 'Parraro principal',
        'text': 'Para <span class="color-text-dark-important semi-bold">Contacrece</span>, la relación con nuestros clientes no se trata simplemente de un servicio para generar información contable o fiscal. La Contabilidad de Clase Mundial © que mes a mes reciben nuestros clientes es solo una consecuencia natural del trabajo que en conjunto llevamos a cabo en la empresa de nuestros clientes.'
      }, {
        'id': 'section2',
        'name': 'Sub titulo',
        'text': 'Contabilidad para el Crecimiento'
      }, {
        'id': 'section3',
        'name': 'texto',
        'text': 'Es precisamente en árras del crecimiento de nuestros clientes que hemos desarrollado a lo largo de 15 años la capacidad de transmitir la experiencia obtenida en empresas medianas y grandes, y transformarla en el <span class="color-text-dark-important semi-bold">Blindaje Contacrece</span>. De esta manera contribuimos a desarrollar las capacidades administrativas de la pequeña y mediana empresa sin necesariamente tener que pagar costos millonarios para recibir la misma calidad de resultado.<br/><br/>Hemos hecho de nuestra pasión nuestro propósito y misión. Sin embargo hemos tenido el cuidado de considerar a quienes conforman la base elemental que ha hecho de nuestra compañía exitosa:'
      },
      { 'id': 'background', 'name': 'Imagen de fondo', 'text': '/img/001_quienesomos.svg' }
    ]
  };

  constructor(props) {
    super(props);
    
    getDataComponentByIdName('05InfoMainAboutUs')
      .then(result => {
        this.setState({
          ...this.state,
          dataComponent: JSON.parse(result.data)
        });
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          dataComponent: [
            {
              'id': 'section1',
              'name': 'Parraro principal',
              'text': 'Para <span class="color-text-dark-important semi-bold">Contacrece</span>, la relación con nuestros clientes no se trata simplemente de un servicio para generar información contable o fiscal. La Contabilidad de Clase Mundial © que mes a mes reciben nuestros clientes es solo una consecuencia natural del trabajo que en conjunto llevamos a cabo en la empresa de nuestros clientes.'
            }, {
              'id': 'section2',
              'name': 'Sub titulo',
              'text': 'Contabilidad para el Crecimiento'
            }, {
              'id': 'section3',
              'name': 'texto',
              'text': 'Es precisamente en árras del crecimiento de nuestros clientes que hemos desarrollado a lo largo de 15 años la capacidad de transmitir la experiencia obtenida en empresas medianas y grandes, y transformarla en el <span class="color-text-dark-important semi-bold">Blindaje Contacrece</span>. De esta manera contribuimos a desarrollar las capacidades administrativas de la pequeña y mediana empresa sin necesariamente tener que pagar costos millonarios para recibir la misma calidad de resultado.<br/><br/>Hemos hecho de nuestra pasión nuestro propósito y misión. Sin embargo hemos tenido el cuidado de considerar a quienes conforman la base elemental que ha hecho de nuestra compañía exitosa:'
            },
            { 'id': 'background', 'name': 'Imagen de fondo', 'text': '/img/001_quienesomos.svg' }
          ]
        });
      });
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div className="section-que-es-contabilidad-nosotros">
        <Container maxWidth="lg">
          <div
            className="title text-center"
            data-aos="fade-up"
            data-aos-once="true">
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[0].text) }}>
            </div>
          </div>
          <div className="container-info">
            <div className="general-info">
              <div>
                <div className="image-general-info">
                  <img
                    className="w-100"
                    src={this.state.dataComponent[3].text}
                    alt=""
                    data-aos="fade-up"
                    data-aos-once="true"/>
                </div>
              </div>
              <div>
                <div className="container-line color-text-dark">
                  <div
                    className="line-left"></div>
                  <div>
                    <img
                      className="contacrece-logo"
                      data-aos="fade-up"
                      data-aos-once="true"
                      src="/img/logo_contacrece.svg" alt=""/>
                  </div>
                  <p
                    data-aos="fade-up"
                    data-aos-once="true"
                    className="color-text-dark-semi semi-bold"
                    dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[1].text) }}>
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-once="true"
                    dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[2].text) }}>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    )
  } 
}

export default WhatTypicalMorning;