import React, { Component } from 'react';

import SectionHeader from '../../generals/WorldClass/sectionHeader/SectionHeader';
import WhatTypicalMorning from '../../generals/WorldClass/whatTypicalMorning/WhatTypicalMorning';
import SectionHelp from '../../generals/sectionHelp/SectionHelp';
import SectionContact from '../../generals/sectionContact/SectionContact';
import Product from '../../generals/WorldClass/product/Product';

class WorldClass extends Component {
  render() {
    return (
      <div>
        <SectionHeader />
        <WhatTypicalMorning />
        <Product />
        <SectionContact>
          <SectionHelp />
        </SectionContact>
      </div>
    );
  }
}

export default WorldClass;