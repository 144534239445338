import React, { Component } from 'react';
import Container from '@material-ui/core/Container';

import Header from '../../generals/header/Header';

import './noticePrivacy.scss';

class NoticePrivacy extends Component {
  render() {
    return (
      <div className="container-notice-privacy">
        <Container maxWidth="xl">
          <Header logo="/img/023_logogris.svg" />
          <div className="container-text">
            <Container maxWidth="lg">
              <div className="container-noticePrivacy">
                <div className="mb-4">
                  <div className="title">
                    AVISO DE PRIVACIDAD
                  </div>
                  <div className="normal text-center">
                    Revisa nuestro aviso de privacidad.
                  </div>
                </div>

                <div className="subtitle">
                  AVISO DE PRIVACIDAD DE CONTACRECE S.C.
                </div>
                <div className="section normal">
                  Atendiendo a lo previsto por la Ley Federal de Protección de Datos Personales en
                  Posesión de los Particulares en sus artículos 15 y 16, y en cumplimiento de la
                  obligación a cargo de CONTACRECE S.C. consistente en la protección de datos
                  personales de sus clientes, afiliados y beneficiados de cualquiera de los productos
                  o servicios pertenecientes a la razón social en cuestión, hace del conocimiento
                  público lo siguiente:
                </div>

                <div className="subtitle">
                  1. RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES
                </div>
                <div className="section normal">
                  CONTACRECE S.C. (en adelante denominado como “La EMPRESA”) es la
                  sociedad responsable del tratamiento de aquellos datos personales de los clientes,
                  afiliados o beneficiados recabados y tratados por la misma. Los datos personales
                  que hayan sido proporcionados tanto por dichos sujetos vía electrónica en la
                  página de Internet <a href="https://www.contacrece.com" target="_blank" rel="noopener noreferrer">www.contacrece.com</a> o por cualquier otro medio, podrán ser
                  utilizados por La EMPRESA.
                </div>

                <div className="subtitle">
                  2. DEPARTAMENTO DE DATOS PERSONALES
                </div>
                <div className="section normal">
                  Domicilio: Calle La Luna 2658, Col. Jardines del Bosque, Guadalajara, Jal.  México  C.P. 44520 <br/>
                  Contacto: Aldo López al correo electrónico: alopez@contacrece.com.mx o al
                  teléfono: (33)16390600 extensión 680.
                </div>

                <div className="subtitle">
                  3. FINALIDADES DE LA OBTENCIÓN DE DATOS PERSONALES
                </div>
                <div className="section normal">

                  <ul>
                    <li>Proveer los servicios y productos requeridos por el cliente;</li>
                    <li>Informar sobre cambios o nuevos productos o servicios relacionados con el
                  contrato inicial celebrado por el cliente;</li>
                    <li>Dar cumplimiento a obligaciones contraídas con el cliente;</li>
                    <li>Evaluar la calidad del servicio,</li>
                    <li>Mantener registro de los visitantes que acceden a las instalaciones, y</li>
                    <li>Realizar estudios internos.</li>
                  </ul>

                  <p className="normal">
                    Para garantizar la protección de datos personales y limitar el uso o divulgación no
                    autorizada de los mismos, La EMPRESA guardará confidencialidad respecto de
                    dicha información, administrando las bases de datos que la contienen por las
                    personas designadas por La EMPRESA exclusivamente para tal efecto, sin que se
                    permita su uso, consulta, manejo o acceso a personas no autorizadas. La
                    EMPRESA procurará que los datos personales contenidos en las bases de datos
                    sean pertinentes, correctos y actualizados para los fines para los cuales fueron
                    recabados. Cuando los datos de carácter personal hayan dejado de ser
                    necesarios para el cumplimiento de las finalidades previstas por el presente Aviso
                    de Privacidad y las disposiciones legales aplicables, serán cancelados. La
                    EMPRESA de la base de datos se compromete a eliminar la información relativa al
                    incumplimiento de obligaciones contractuales, una vez que transcurra un plazo de setenta y dos meses, contado a partir de la fecha calendario en que se presente el
                    mencionado incumplimiento.
                  </p>
                  <p className="normal">
                    De igual manera, dichas bases de datos se encuentran protegidas por los medios
                    electrónicos enfocados a prevenir y evitar que personas ajenas o no autorizadas
                    por La EMPRESA puedan tener acceso a datos personales. La persona que se
                    ostente como titular de datos personales, puede presentar o enviar a La
                    EMPRESA una solicitud para el acceso, rectificación, cancelación u oposición
                    respecto de la información proporcionada por dicha persona. La solicitud
                    previamente mencionada podrá ser presentada física o electrónicamente al
                    domicilio o contactos previamente descritos en el segundo numeral del presente
                    documento.
                  </p>
                  <p className="normal">
                    La EMPRESA se reserva el derecho a realizar en cualquier momento
                    modificaciones o actualizaciones al presente Aviso de Privacidad, con el fin de
                    aplicar reformas legislativas, políticas internas, prácticas comerciales o nuevos
                    requerimientos para la prestación u ofrecimiento de sus productos y servicios.
                    Dichas modificaciones y actualizaciones serán publicadas en nuestra página de
                    internet <a href="https://www.contacrece.com" target="_blank" rel="noopener noreferrer">www.contacrece.com.</a>
                  </p>
                </div>

                <div className="subtitle">
                  4. DATOS PERSONALES
                </div>
                <div className="section normal">
                  Para las finalidades anteriormente mencionadas, la obtención de datos personales
                  podrá llevarse a cabo de manera (1) Directa: Cuando el cliente directamente lo
                  proporcione, ya sea al personal de La EMPRESA o a través de la página web 
                  <a href="https://www.contacrece.com" target="_blank" rel="noopener noreferrer"> www.contacrece.com </a>; o (2) Indirecta: A través de otras fuentes permitidas por la
                  Ley con el expreso consentimiento del titular, como lo establece el octavo artículo
                  de la Ley Federal de Protección de Datos Personales en Posesión de los
                  Particulares. Sin embargo, no será necesario el consentimiento para el tratamiento
                  de los datos personales cuando: (1) Cualquier Ley Vigente o disposición jurídica
                  aplicable lo permita; (2) Los datos figuren en fuentes de acceso público; (3) Los
                  datos personales se sometan a un procedimiento previo de disociación; (4) Tenga
                  el propósito de cumplir obligaciones derivadas de una relación jurídica entre el
                  titular y el responsable; (5) Exista una situación de emergencia que
                  potencialmente pueda dañar a un individuo en su persona o en sus bienes; (6)
                  Sean indispensables para la atención médica, la prevención, diagnóstico, la
                  prestación de asistencia sanitaria, tratamientos médicos o la gestión de servicios
                  sanitarios, mientras el titular no esté en condiciones de otorgar el consentimiento,
                  en los términos que establece la Ley General de Salud y demás disposiciones
                  jurídicas aplicables y que dicho tratamiento de datos se realice por una persona
                  sujeta al secreto profesional u obligación equivalente, o (7) Se dicte resolución al
                  respecto de autoridad competente.
                </div>

                <div className="subtitle">
                  5. DATOS SENSIBLES
                </div>
                <div className="section normal">
                  Tratándose de datos personales sensibles, La EMPRESA se compromete a
                  obtener el consentimiento expreso y por escrito del titular para su tratamiento, a
                  través de su firma autógrafa, firma electrónica, o cualquier mecanismo de

                  autenticación que al efecto se establezca. Queda descartada la creación de bases
                  de datos que contengan datos personales sensibles, sin que se justifique el
                  establecimiento de las mismas para finalidades legítimas, concretas y acordes con
                  las actividades o fines explícitos que persigue La EMPRESA o que no cuenten con
                  el consentimiento expreso del titular.
                </div>

                <div className="subtitle">
                  6. DATOS FINANCIEROS Y PATRIMONIALES
                </div>
                <div className="section normal">
                  En caso de resultar necesario para el cumplimiento de las obligaciones contraídas
                  con la clientela o personal beneficiado o afiliado, La EMPRESA recabará los datos
                  financieros y patrimoniales objeto fin de la relación jurídica creada entre La
                  EMPRESA y la contraparte, únicamente con el consentimiento expreso del titular
                  de la información o su apoderado legal.
                </div>

                <div className="subtitle">
                  7. MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE DATOS PERSONALES
                </div>
                <div className="section normal">
                  El titular de la información recabada por La EMPRESA, en caso de considerarlo
                  necesario, podrá solicitar vía correo electrónico, telefónica o presencialmente al
                  Departamento de Datos Personales de La EMPRESA, la limitación de datos
                  específicos cuyo contenido deba ser tratado como sensible o referente al acceso,
                  rectificación, cancelación u oposición respecto de la información proporcionada de
                  conformidad al procedimiento establecido en el numeral siguiente.
                </div>

                <div className="subtitle">
                  8. PROCEDIMIENTO PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN
                </div>
                <div className="section normal">
                  <p className="normal">
                    El titular de la información tiene el derecho de acceder a los datos personales
                    proporcionados y a los detalles del tratamiento de los mismos, así como a
                    rectificarlos en caso de ser inexactos o incompletos, o cancelarlos cuando
                    considere que no se requieren para alguna de las finalidades señaladas en el
                    numeral tercero del presente Aviso de Privacidad, estén siendo utilizados para
                    finalidades no consentidas, haya finalizado la relación de servicio, o bien,
                    oponerse al tratamiento de los mismos para fines específicos.
                  </p>
                  <p className="normal">
                    Los mecanismos que se han implementado para el ejercicio de dichos derechos
                    son a través de lapresentación de la solicitud respectiva en: (1) Por escrito y de
                    manera presencial en el Departamento de Datos Personales ubicado en el
                    domicilio antes señalado, o (2) Por correo electrónico, a la siguiente dirección
                    alopez@contacrece.com.
                  </p>
                  <p className="normal">
                    Su solicitud deberá contener la siguiente información:
                  </p>
                  <ul>
                    <li>El nombre y domicilio del titular o cualquier otro medio para recibir la respuesta (si el titular omite la dirección o cualquier otro medio que haya elegido para contactarlo, se tendrá por no recibida la solicitud).</li>
                    <li>Los documentos que acrediten la identidad o la personalidad de su representante.La descripción clara y precisa de los datos personales respecto de los cuales el titular busca ejercer alguno de los derechos.</li>
                    <li>
                      En su caso, otros elementos o documentos que faciliten la localización de los datos personales.
                    </li>
                  </ul>

                  <p className="normal">
                    Si la solicitud del titular es errónea, incompleta o no es clara, La EMPRESA
                    requerirá al titular para que aporte información adicional para atender su petición
                    dentro de los 5 cinco días hábiles siguientes a la recepción de la solicitud. De no
                    dar respuesta, la solicitud se tendrá por no presentada.
                  </p>

                  <p className="normal">
                    El plazo para atender una solicitud no podrá exceder de veinte días hábiles a partir
                    del ingreso. En caso de que se haya requerido información adicional, dicho plazo
                    empezará a computarse al día siguiente en que el titular haya proporcionado la
                    información requerida. Se informará de manera puntual al titular sobre la
                    procedencia de la misma a través del medio que haya señalado para tal efecto. <br/>
                    El presente documento se encuentra disponible y abierto al público en la siguiente
                    dirección electrónica: <a href="https://www.contacrece.com/aviso-de-privacidad" target="_blank" rel="noopener noreferrer">www.contacrece.com/aviso-de-privacidad</a>
                  </p>
                </div>

                <div className="subtitle">
                  9. DERECHO DE PROMOVER PROCEDIMIENTOS DE PROTECCIÓN DE DERECHOS Y VERIFICACIÓN QUE SUSTANCIA EL INSTITUTO FEDERAL DE ACCESO A LA INFORMACIÓN PÚBLICA (IFAI):
                </div>
                <div className="section normal">
                  <p className="normal">
                    Cualquier queja o información adicional respecto al tratamiento de los datos
                    personales o duda en relación con la Ley Federal de Protección de Datos
                    Personales en Posesión de los Particulares o con su Reglamento, el titular podrá
                    dirigirla al IFAI por medio de su página web: <a href="http://www.ifai.org.mx" target="_blank" rel="noopener noreferrer">www.ifai.org.mx.</a>
                  </p>
                  <p className="normal">
                    Declaro que he leído y comprendido el presente aviso de privacidad estando de
                    acuerdo con el contenido y aplicación del mismo.
                  </p>
                </div>
                <div>
                  <div className="flex-center">
                    <div className="container-footer-info mr-3">
                      © 2020 Contacrece
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </Container>
      </div>
    );
  }
}

export default NoticePrivacy;