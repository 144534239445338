import React, { Component } from 'react';

import { Waypoint } from 'react-waypoint';
import CountUp from 'react-countup';

import './ProgressCircle.scss';

class ProgressCircle extends Component {

  id = 0;
  actived = false;
  state = {
    porcent100: 629,
    color1: 1,
    color2: .8,
    color3: .6,
    color4: .4,
    color5: .2,
    color6: 0,
    porcentEject: 0
  }

  constructor(props) {
    super(props);
    this.id = Math.random();
  }

  loadCircle(event) {
    if (!this.actived) {
      this.actived = true;
      this.setState({
        porcentEject: this.props && this.props.porcent ? this.props.porcent : 0
      }); 
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.porcent >= 0 && props.porcent <= 29) {
      return {
        porcent: 20,
        porcentText: props.porcent,
        color1: 0,
        color2: 0,
        color3: 0,
        color4: 0,
        color5: 0,
        color6: 0
      };
    }

    if (props.porcent >= 30 && props.porcent <= 49) {
      return {
        porcent: 40,
        porcentText: props.porcent,
        color1: 1,
        color2: 0,
        color3: 0,
        color4: 0,
        color5: 0,
        color6: 0
      };
    }

    if (props.porcent >= 50 && props.porcent <= 69) {
      return {
        porcent: 60,
        porcentText: props.porcent,
        color1: 1,
        color2: 1,
        color3: 0,
        color4: 0,
        color5: 0,
        color6: 0
      };
    }

    if (props.porcent >= 70 && props.porcent <= 89) {
      return {
        porcent: 80,
        porcentText: props.porcent,
        color1: 1,
        color2: 1,
        color3: 1,
        color4: 0,
        color5: 0,
        color6: 0
      };
    }

    if (props.porcent >= 90 && props.porcent <= 99) {
      return {
        porcent: 90,
        porcentText: props.porcent,
        color1: 1,
        color2: 1,
        color3: 1,
        color4: 1,
        color5: 0,
        color6: 0
      };
    }

    if (props.porcent >= 100 && props.porcent <= 100) {
      return {
        porcent: 100,
        porcentText: props.porcent,
        color1: 1,
        color2: 1,
        color3: 1,
        color4: 1,
        color5: 1,
        color6: 0
      };
    }
  }

  calculatePorcent = (porcent = 0) => ((this.state.porcent100 * porcent) / 100);

  render() {
    return (
      <div className={`progressCircle ${this.actived ? 'actived' : '' }`}>
        <Waypoint
          scrollableAncestor={window}
          onEnter={this.loadCircle.bind(this)}
          bottomOffset='30%'
        />
        <div className="progress">
          <div className="bar" data-name="SVG Skill" data-percent={`${this.state.porcentText}%`}>
            <svg viewBox="-10 -10 210 220">
              <g fill="none" strokeWidth="5" transform="translate(100,100)">
                <path d="M 0,-100 A 100,100 0 0,1 86.6,-50" stroke={`url(#cl1-${this.id})`} strokeLinecap="round"/>
                <path d="M 86.6,-50 A 100,100 0 0,1 86.6,50" stroke={`url(#cl2-${this.id})`}/>
                <path d="M 86.6,50 A 100,100 0 0,1 0,100" stroke={`url(#cl3-${this.id})`}/>
                <path d="M 0,100 A 100,100 0 0,1 -86.6,50" stroke={`url(#cl4-${this.id})`}/>
                <path d="M -86.6,50 A 100,100 0 0,1 -86.6,-50" stroke={`url(#cl5-${this.id})`}/>
                <path d="M -86.6,-50 A 100,100 0 0,1 0,-100" stroke={`url(#cl6-${this.id})`}/>
              </g>
            </svg>
            <svg viewBox="-10 -10 220 220">
              <path
                d="M200,100 C200,44.771525 155.228475,0 100,0 C44.771525,0 0,44.771525 0,100 C0,155.228475 44.771525,200 100,200 C155.228475,200 200,155.228475 200,100 Z"
                strokeDashoffset={this.calculatePorcent(this.state.porcentEject)}>
              </path>
            </svg>
            <div className="container-info">
              <div className="porcent-text" style={{ color: `rgba(${this.props.color}, 1)` }}>
                <CountUp delay={2} end={this.state.porcentEject} />%
              </div>
              {this.props.children}
            </div>
          </div>
          <svg width="0" height="0">
            <defs>
              <linearGradient id={`cl1-${this.id}`} gradientUnits="objectBoundingBox" x1="0" y1="0" x2="1" y2="1">
                <stop stopColor={`rgba(${this.props.color}, 1)`}/>
                <stop offset="100%" stopColor={`rgba(${this.props.color}, ${this.state.color1})`}/>
              </linearGradient>
              <linearGradient id={`cl2-${this.id}`} gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1">
                <stop stopColor={`rgba(${this.props.color}, ${this.state.color1})`}/>
                <stop offset="100%" stopColor={`rgba(${this.props.color}, ${this.state.color2})`}/>
              </linearGradient>
              <linearGradient id={`cl3-${this.id}`} gradientUnits="objectBoundingBox" x1="1" y1="0" x2="0" y2="1">
                <stop stopColor={`rgba(${this.props.color}, ${this.state.color2})`}/>
                <stop offset="100%" stopColor={`rgba(${this.props.color}, ${this.state.color3})`}/>
              </linearGradient>
              <linearGradient id={`cl4-${this.id}`} gradientUnits="objectBoundingBox" x1="1" y1="1" x2="0" y2="0">
                <stop stopColor={`rgba(${this.props.color}, ${this.state.color3})`}/>
                <stop offset="100%" stopColor={`rgba(${this.props.color}, ${this.state.color4})`}/>
              </linearGradient>
              <linearGradient id={`cl5-${this.id}`} gradientUnits="objectBoundingBox" x1="0" y1="1" x2="0" y2="0">
                <stop stopColor={`rgba(${this.props.color}, ${this.state.color4})`}/>
                <stop offset="100%" stopColor={`rgba(${this.props.color}, ${this.state.color5})`}/>
              </linearGradient>
              <linearGradient id={`cl6-${this.id}`} gradientUnits="objectBoundingBox" x1="0" y1="1" x2="1" y2="0">
                <stop stopColor={`rgba(${this.props.color}, ${this.state.color5})`}/>
                <stop offset="100%" stopColor={`rgba(${this.props.color}, ${this.state.color6})`}/>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    )
  } 
}

export default ProgressCircle;