import React, { Component } from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Hidden from '@material-ui/core/Hidden';

import OfficeSvg from './office';

import './Points.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Container from '@material-ui/core/Container';

class Points extends Component {

  state = {
    dataComponent: [
      { 'id': 'title', 'name': 'titulo', 'text': 'Mañana Típica <br/> Contacrece <span class="text-normal">©</span>' }
    ],
    expanded: false
  };

  // constructor(props) {
  //   super(props);
    
  //   getDataComponentByIdName('')
  //     .then(result => {
  //       console.log(result);
  //       this.setState({
  //         ...this.state,
  //         dataComponent: JSON.parse(result.data)
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       this.setState({
  //         ...this.state,
  //         dataComponent: [
  //           { 'id': 'title', 'name': 'titulo', 'text': 'Tranquilidad Administrativa <br/> y Contable' },
  //           { 'id': 'text', 'name': 'texto', 'text': 'Resolvemos la falta de información confiable administrativa, <br/> contable y fiscal, que impide una adecuada <br/> toma de decisiones' }
  //         ]
  //       });
  //     });
  // }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  handleChange (panel) {
    this.setState({
      expanded: this.state.expanded === panel ? false : panel
    });
  };

  render() {
    return (
      <div className="section-Points">
        <Hidden smDown>
          <Container maxWidth="lg">
            <div
              className="container-office-svg"
              data-aos="fade-up"
              data-aos-once="true">
              <OfficeSvg />
            </div>
          </Container>
        </Hidden>
        <Hidden mdUp>
          <Container maxWidth="lg">
            <div className="container-expands mt-3">
              <div
                className="content-expand"
                data-aos="fade-up"
                data-aos-once="true">
                <ExpansionPanel expanded={this.state.expanded === 'panel1'} onChange={() => this.handleChange('panel1')}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <div className="title-expand">
                      <div className="container-title-expand">
                        <div className="title">
                          Cuentas <span className={`${this.state.expanded === 'panel1' ? 'active' : ''}`}> por cobrar </span>
                        </div>
                      </div>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="container-section-info-expand">
                      <div className="container-info">
                        <p>Saldo por documento</p>
                        <p>Saldo por cliente</p>
                        <p>Antigüedad de la cartera</p>
                        <p>Vencimiento de las cuentas por cobrar</p>
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              <div
                className="content-expand" 
                data-aos="fade-up"
                data-aos-once="true">
                <ExpansionPanel expanded={this.state.expanded === 'panel2'} onChange={() => this.handleChange('panel2')}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <div className="title-expand">
                      <div className="container-img">
                      </div>
                      <div className="container-title-expand">
                        <div className="title">
                          Cuentas <span className={`${this.state.expanded === 'panel2' ? 'active' : ''}`}> por pagar </span>
                        </div>
                      </div>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="container-section-info-expand">
                      <div className="container-info">
                        <p>Saldo por documento</p>
                        <p>Saldo por proveedor</p>
                        <p>Antigüedad de las cuentas por pagar</p>
                        <p>Vencimiento de las cuentas por pagar</p>
                        <p>Programación de pagos</p>
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              <div
                className="content-expand"
                data-aos="fade-up"
                data-aos-once="true">
                <ExpansionPanel expanded={this.state.expanded === 'panel3'} onChange={() => this.handleChange('panel3')}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <div className="title-expand">
                      <div className="container-img">
                      </div>
                      <div className="container-title-expand">
                        <div className="title">
                          <span className={`${this.state.expanded === 'panel3' ? 'active' : ''}`}> Pagos </span>
                        </div>
                      </div>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="container-section-info-expand">
                      <div className="container-info">
                        <p>Detalles de pagos realizados</p>
                        <p>Estadísticas de pagos y otros egresos por periodos (día, semana, mes, año)</p>
                        <p>Estadísticas de pagos por proveedor</p>
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              <div
                className="content-expand"
                data-aos="fade-up"
                data-aos-once="true">
                <ExpansionPanel expanded={this.state.expanded === 'panel4'} onChange={() => this.handleChange('panel4')}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <div className="title-expand">
                      <div className="container-img">
                      </div>
                      <div className="container-title-expand">
                        <div className="title">
                          <span className={`${this.state.expanded === 'panel4' ? 'active' : ''}`}> Cobranza </span>
                        </div>
                      </div>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="container-section-info-expand">
                      <div className="container-info">
                        <p>Detalles de ingresos recibidos</p>
                        <p>Estadísticas de cobranza y otros ingresos por periodos (día, semana, mes, año)</p>
                        <p>Estadísticas de cobranza por cliente o agente</p>
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              <div
                className="content-expand"
                data-aos="fade-up"
                data-aos-once="true">
                <ExpansionPanel expanded={this.state.expanded === 'panel5'} onChange={() => this.handleChange('panel5')}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <div className="title-expand">
                      <div className="container-img">
                      </div>
                      <div className="container-title-expand">
                        <div className="title">
                          <span className={`${this.state.expanded === 'panel5' ? 'active' : ''}`}> Compras </span>
                        </div>
                      </div>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="container-section-info-expand">
                      <div className="container-info">
                        <p>Compras clasificadas según necesidades de la empresa</p>
                        <p>Estadísticas de compras por periodos (día, semana, mes, año)</p>
                        <p>Estadísticas de compras por proveedor</p>
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              <div
                className="content-expand"
                data-aos="fade-up"
                data-aos-once="true">
                <ExpansionPanel expanded={this.state.expanded === 'panel6'} onChange={() => this.handleChange('panel6')}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <div className="title-expand">
                      <div className="container-img">
                      </div>
                      <div className="container-title-expand">
                        <div className="title">
                          <span className={`${this.state.expanded === 'panel6' ? 'active' : ''}`}> Ventas </span>
                        </div>
                      </div>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="container-section-info-expand">
                      <div className="container-info">
                        <p>Ventas clasificadas según necesidades de la empresa</p>
                        <p>Estadísticas de venta por periodos (día, semana, mes, año)</p>
                        <p>Estadísticas de venta por cliente o agente</p>
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              <div
                className="content-expand"
                data-aos="fade-up"
                data-aos-once="true">
                <ExpansionPanel expanded={this.state.expanded === 'panel7'} onChange={() => this.handleChange('panel7')}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <div className="title-expand">
                      <div className="container-img">
                      </div>
                      <div className="container-title-expand">
                        <div className="title">
                          <span className={`${this.state.expanded === 'panel7' ? 'active' : ''}`}> Tesoreria </span>
                        </div>
                      </div>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="container-section-info-expand">
                      <div className="container-info">
                        <p>Conciliaciones bancarias diarias</p>
                        <p>Disponibilidad de saldos en cuentas de cheques y créditos revolventes</p>
                        <p>Movimientos en transito</p>
                        <p>Seguimiento a depósitos no identificados</p>
                        <p>Detalles de ingresos y egresos</p>
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            </div>
          </Container>
        </Hidden>
      </div>
    )
  } 
}

export default Points;