import React, { Component } from 'react';

import './WhatTypicalMorning.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Container from '@material-ui/core/Container';

import { getDataComponentByIdName, replaceNewLine } from '../../utils/getDataComponents';

class WhatTypicalMorning extends Component {

  state = {
    dataComponent: [
      { 'id': 'title', 'name': 'titulo', 'text': '¿Qué es la mañana Típica Contacrece<span class="text-normal">©</span>?' },
      { 'id': 'subTitle', 'name': 'sub titulo', 'text': 'El día a día de todas las empresas, require de la información administrativa confiable y oportuna que permita que el proceso administrativo de planeación, organización, ejecución y control se logre de una manera efectiva en las distintas áreas de la empresa.' },
      { 'id': 'paragraph1', 'name': 'Parrafo 1', 'text': '<span class="color-text-dark-semi semi-bold">La Mañana Típica Contacrece</span> es el primer paso de una serie de procesos que denominamos <span class="color-text-dark-semi semi-bold">Blindaje Contacrece</span>.' },
      { 'id': 'paragraph2', 'name': 'Parrafo 2', 'text': 'Está metodológicamente diseñada para mediante procesos estandarizados de ingeniería, se ejecute todos los días a primera hora de la mañana por el Asistente Administrativo dentro de su empresa.' },
      { 'id': 'paragraph3', 'name': 'Parrafo 3', 'text': 'Consiste en la actualización de una serie de reportes administrativos bajo el estándar de calidad <span class="color-text-dark-semi semi-bold">PRODUCT</span> desarrollado y probado por <span class="color-text-dark-semi semi-bold">Contacrece</span> por más de 15 años.' },
      { 'id': 'paragraph4', 'name': 'Parrafo 4', 'text': 'El resultado de la implementación de la <span class="color-text-dark-semi semi-bold">Mañana Típica Contacrece</span> se resume en que el empresario retome el control del día a día de su empresa. De igual manera, el trabajo realizado por el Asistente Administrativo trae por consecuencia darle al empresario y los tomadores de decisiones el conocimiento, certeza y confiabilidad de la información relacionada a Tesorería, Ventas, Ingresos, Egresos, Compras, Cuentas por cobrar, y Cuentas por pagar principalmente.' },
      { 'id': 'paragraph5', 'name': 'Parrafo 5', 'text': '<span class="color-text-dark-semi semi-bold">Todos estos factores son de gran relevancia para facilitar y mejorar el proceso de toma de decisiones diaria de la empresa.</span>' },
      { 'id': 'background', 'name': 'Imagen de fondo', 'text': '/img/001_Mananatipica@0.75x-100.jpg' }
    ]
  };

  constructor(props) {
    super(props);
    
    getDataComponentByIdName('02SectionTypicalMorning')
      .then(result => {
        this.setState({
          ...this.state,
          dataComponent: JSON.parse(result.data)
        });
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          dataComponent: [
            { 'id': 'title', 'name': 'titulo', 'text': '¿Qué es la mañana Típica Contacrece<span class="text-normal">©</span>?' },
            { 'id': 'subTitle', 'name': 'sub titulo', 'text': 'El día a día de todas las empresas, require de la información administrativa confiable y oportuna que permita que el proceso administrativo de planeación, organización, ejecución y control se logre de una manera efectiva en las distintas áreas de la empresa.' },
            { 'id': 'paragraph1', 'name': 'Parrafo 1', 'text': '<span class="color-text-dark-semi semi-bold">La Mañana Típica Contacrece</span> es el primer paso de una serie de procesos que denominamos <span class="color-text-dark-semi semi-bold">Blindaje Contacrece</span>.' },
            { 'id': 'paragraph2', 'name': 'Parrafo 2', 'text': 'Está metodológicamente diseñada para mediante procesos estandarizados de ingeniería, se ejecute todos los días a primera hora de la mañana por el Asistente Administrativo dentro de su empresa.' },
            { 'id': 'paragraph3', 'name': 'Parrafo 3', 'text': 'Consiste en la actualización de una serie de reportes administrativos bajo el estándar de calidad <span class="color-text-dark-semi semi-bold">PRODUCT</span> desarrollado y probado por <span class="color-text-dark-semi semi-bold">Contacrece</span> por más de 15 años.' },
            { 'id': 'paragraph4', 'name': 'Parrafo 4', 'text': 'El resultado de la implementación de la <span class="color-text-dark-semi semi-bold">Mañana Típica Contacrece</span> se resume en que el empresario retome el control del día a día de su empresa. De igual manera, el trabajo realizado por el Asistente Administrativo trae por consecuencia darle al empresario y los tomadores de decisiones el conocimiento, certeza y confiabilidad de la información relacionada a Tesorería, Ventas, Ingresos, Egresos, Compras, Cuentas por cobrar, y Cuentas por pagar principalmente.' },
            { 'id': 'paragraph5', 'name': 'Parrafo 5', 'text': '<span class="color-text-dark-semi semi-bold">Todos estos factores son de gran relevancia para facilitar y mejorar el proceso de toma de decisiones diaria de la empresa.</span>' },
            { 'id': 'background', 'name': 'Imagen de fondo', 'text': '/img/001_Mananatipica@0.75x-100.jpg' }
          ]
        });
      });
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div className="section-WhatTypicalMorning">
        <div
          className="title text-center"
          data-aos="fade-up"
          data-aos-once="true">
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[0].text) }}>
          </div>
        </div>
        <Container maxWidth="lg">
          <div className="container-info">
            <div
              className="text-center color-text-dark"
              data-aos="fade-up"
              data-aos-once="true"
              dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[1].text) }}>
            </div>
            <div className="general-info">
              <div>
                <div className="image-general-info">
                  <img
                    className="w-100"
                    src={this.state.dataComponent[7].text}
                    alt=""
                    data-aos="fade-up"
                    data-aos-once="true"/>
                </div>
              </div>
              <div>
                <div className="container-line color-text-dark">
                  <div
                    className="line-left"></div>
                  <div
                    data-aos="fade-up"
                    data-aos-once="true"
                    dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[2].text) }}>
                  </div>

                  <br/>

                  <div
                    data-aos="fade-up"
                    data-aos-once="true"
                    dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[3].text) }}>
                  </div>

                  <br/>

                  <div
                    data-aos="fade-up"
                    data-aos-once="true"
                    dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[4].text) }}>
                    
                  </div>

                  <br/>

                  <div
                    data-aos="fade-up"
                    data-aos-once="true"
                    dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[5].text) }}>
                  </div>

                  <br/>

                  <div
                    data-aos="fade-up"
                    data-aos-once="true"
                    dangerouslySetInnerHTML={{ __html: replaceNewLine(this.state.dataComponent[6].text) }}>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    )
  } 
}

export default WhatTypicalMorning;