import React, { useState } from 'react';
import { Edit, SimpleForm, DisabledInput, TextInput, BooleanInput, SaveButton } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { TextField, Snackbar } from '@material-ui/core';

import './dataComponents.scss';

import UploadImages from './../uploadImages/uploadFiles';

const redirect = (basePath, id, data) => `/components`;

const VisitorForm = (props) => {
  const data = (props.data && props.data.data !== null)? JSON.parse(props.data.data) : [];

  const [getData, setData] = useState(data);
  const [open, setOpen] = useState(false);
  const [openMessage, setMessageOpen] = useState('');

  const changeValue = (index, value) => {
    const newData = [
      ...getData
    ];
    newData[index].text = value;
    setData(newData);
  };

  const generateJson = () => {
    return JSON.stringify(getData);
  };

  const sendData = () => {
    const data = generateJson();

    var miInit = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer ${window.localStorage.getItem('token')}`
      },
      cache: 'no-cache',
      mode :'cors',
      body: JSON.stringify({
        data
      })
    };

    setOpen(true);
    setMessageOpen('Guardando');

    fetch(`${global.config.URL.SERVER}/dataComponents/${props.data.id}`, miInit)
      .then((response) => {
        setOpen(true);
        setMessageOpen('Guardado');
        console.log(response);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <form>
      {getData.map((value, index) => {
        return (
          <div className="containerInput" key={index}>
            <TextField
              multiline
              type="text"
              label={value.name}
              name={value.id}
              value={value.text}
              className="inputs"
              onChange={e => changeValue(index, e.target.value)}/>
          </div>
        )
      })}
      <SaveButton onClick={() => sendData()} />
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={openMessage}>
      </Snackbar>
    </form>
  );
}

export class DataComponentEdit extends React.Component {

  state = {
    data: {}
  };

  constructor(props) {
    super(props);
    this.getDataComponents();
  }

  getDataComponents() {
    var miInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer ${window.localStorage.getItem('token')}`
      },
      cache: 'no-cache',
      mode :'cors'
    };

    fetch(`${global.config.URL.SERVER}/dataComponents/${this.props.id}`, miInit)
      .then((response) => response.json())
      .then((data) => this.setState({ data }));
  };

  render() {
    return (
      <div className="formToChangeData">
        <div className="mb-3">
          <UploadImages />
        </div>
        <Edit
          className="container-form"
          title={'Editar componente'}
          {...this.props}
        >
          {this.state.data.data && (
              <VisitorForm data={this.state.data}/>
            )
          }
        </Edit>
      </div>
    );
  }
}
