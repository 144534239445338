function getDataComponentByIdName(name) {
  return new Promise((resolve, reject) => {
    var miInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json'
      },
      cache: 'no-cache',
      mode :'cors'
    };
  
    fetch(`${global.config.URL.SERVER}/dataComponents/getData/${name}`, miInit)
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
}

function replaceNewLine(text) {
  return text.replace(/\n/g, "<br />");
}

function orderArray(items) {
  return items.sort(function (a, b) {
    if (a.order > b.order) {
      return 1;
    }
    if (a.order < b.order) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });
}

module.exports = {
  getDataComponentByIdName,
  replaceNewLine,
  orderArray
};
